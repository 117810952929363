import styled from 'styled-components';

export const Container = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 15px;
  background-color: var(--color-grey-ef);
  transform: rotateY(180deg);
  position: relative;
  overflow: hidden;
  ${({ $isBlur }) => $isBlur && 'filter: blur(10px);'}
  ${({ $imageUrl }) =>
    $imageUrl &&
    `
      background-image: url(${$imageUrl});
      background-repeat: no-repeat;
      background-size: cover;
      background-position: initial;
  `}
`;

export const Title = styled.h2`
  font: var(--font-32);
  letter-spacing: -0.5px;
  color: ${({ $color }) => $color};
  text-align: center;
  text-transform: uppercase;
  z-index: 1;
  -webkit-transform: translate3d(0, 0, 0);
  margin: 0 60px;
  @media (orientation: landscape) and (max-width: 699px), (max-height: 699px) {
    font: var(--font-22);
    letter-spacing: -0.4px;
    margin: 0 35px;
  }
  @media (orientation: portrait) and (max-width: 699px) {
    font: var(--font-22);
    letter-spacing: -0.4px;
    margin: 0 35px;
  }
`;

export const VideoWrap = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 0;
  height: 100%;
  min-height: 100%;
  width: auto;
  min-width: 100%;
  background-color: var(--color-grey-ef);
  pointer-events: none;
  & > div {
    height: 100%;
    aspect-ratio: 16/9;
  }
  & iframe {
    height: 100%;
    aspect-ratio: 16/9;
  }
`;
