import React from 'react';

const CloseIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" height="10" width="10" viewBox="4 4 12 12">
    <path
      fill="#000"
      d="M5.375 15.271 4.729 14.625 9.354 10 4.729 5.375 5.375 4.729 10 9.354 14.625 4.729 15.271 5.375 10.646 10 15.271 14.625 14.625 15.271 10 10.646Z"
    />
  </svg>
);

export default CloseIcon;
