import styled from 'styled-components';
import { motion } from 'framer-motion';
import { NavLink } from 'react-router-dom';

export const Container = styled(motion.div)`
  display: grid;
  grid-template-columns: auto auto;
  grid-template-rows: 48px;
`;

export const Button = styled.button`
  height: 100%;
  width: 96px;
  background-color: var(--color-grey-ef);
  @media (max-width: 991px) {
    width: 48px;
  }
`;

export const ButtonText = styled.p`
  font: var(--font-14);
  letter-spacing: 0.25px;
  text-transform: uppercase;
  color: var(--color-black);
  text-align: center;
  @media (max-width: 991px) {
    display: none;
  }
`;

export const IconWrap = styled(motion.div).attrs(({ $isOpen }) => ({
  animate: { scale: $isOpen ? [1, 0, 0.75] : [0.75, 0, 1], opacity: [0.99, 0, 1] },
  transition: { duration: 0.3, times: [0, 0, 1] },
  initial: false,
}))`
  display: flex;
  justify-content: center;
  align-items: center;
  @media (min-width: 992px) {
    display: none;
  }
`;

export const Nav = styled(motion.nav).attrs(({ $isOpen }) => ({
  animate: { width: $isOpen ? 'max-content' : 0, paddingLeft: $isOpen ? 2 : 0 },
  transition: { duration: 0.3 },
  initial: false,
}))`
  display: flex;
  column-gap: 2px;
  height: 100%;
  width: 0;
  overflow: hidden;
  padding-left: 0;
  @media (max-width: 991px) {
    column-gap: 0;
  }
`;

export const Link = styled(NavLink)`
  display: flex;
  justify-content: center;
  align-items: center;
  width: fit-content;
  height: 100%;
  font: var(--font-14);
  letter-spacing: 0.25px;
  text-transform: uppercase;
  text-decoration: none;
  color: var(--color-black);
  background-color: var(--color-white);
  transition: background-color 0.3s;
  padding: 0 17px;
  &:active,
  &.active {
    background-color: var(--color-grey-ef);
  }
  @media (hover) {
    &:hover {
      background-color: var(--color-grey-ef);
    }
  }
`;
