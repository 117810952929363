import styled from 'styled-components';

import { Section } from '../styles';

export const Container = styled(Section)`
  background-color: var(--color-black);
  & > p {
    font: var(--font-19);
    letter-spacing: 0px;
    color: var(--color-white);
    margin-bottom: 1em;
    max-width: 690px;
  }
  & > p > a {
    font: var(--font-19);
    letter-spacing: 0px;
    color: var(--color-white);
  }
  padding: 63px 0;
  @media (max-height: 959px), (max-width: 1009px) {
    background-color: var(--color-white);
    & > p {
      color: var(--color-black);
    }
    & > p > a {
      color: var(--color-black);
    }
    padding: 15px 15px 0;
  }
`;
