import styled from 'styled-components';
import { motion } from 'framer-motion';

export const Button = styled.button`
  display: grid;
  grid-template-columns: 48px auto auto;
  grid-template-rows: 48px;
  height: 100%;
  margin-left: 2px;
  &:disabled {
    opacity: 0.5;
    cursor: not-allowed;
  }
`;

export const IconWrap = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  fill: var(--color-white);
  background-color: var(--color-black);
  @media (hover) {
    ${Button}:hover:enabled > & {
      fill: var(--color-grey-a7);
    }
  }
`;

export const TextWrap = styled(motion.div).attrs(({ $isVisible }) => ({
  animate: { width: $isVisible ? 'calc(100vw - 3 * 50px)' : 0, marginLeft: $isVisible ? 2 : 0 },
  transition: { delay: $isVisible ? 0 : 0.1, duration: 0.2 },
  initial: false,
}))`
  height: 100%;
  min-height: 100%;
  max-height: 100%;
  width: 0;
  max-width: 330px;
  background-color: var(--color-black);
  overflow: hidden;
  margin-left: 0;
`;

export const Text = styled(motion.p).attrs(({ $isVisible }) => ({
  animate: { opacity: $isVisible ? 1 : 0 },
  transition: { duration: 0.1, delay: $isVisible ? 0.2 : 0 },
  initial: false,
}))`
  width: fit-content;
  min-width: 200px;
  font: var(--font-14);
  letter-spacing: 0.25px;
  color: var(--color-grey-c1);
  text-transform: uppercase;
  text-align: left;
  padding: 6px 8px 6px 10px;
`;

export const Audio = styled.audio`
  display: block;
  width: 0;
  height: 0;
  opacity: 0;
`;
