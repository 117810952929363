import styled from 'styled-components';
import { motion } from 'framer-motion';

export const TitleWrap = styled.button`
  display: grid;
  grid-template-columns: 49px 1fr;
  grid-gap: 1px;
  align-items: center;
  width: 100%;
  position: sticky;
  top: 0;
  height: 49px;
  min-height: 49px;
  max-height: 49px;
  border-bottom: 1px solid var(--color-white);
  background-color: var(--color-white);
  border-top: 1px solid var(--color-white);
`;

export const DayText = styled.p`
  background-color: ${({ $isGrey }) => ($isGrey ? 'var(--color-grey-ef)' : 'var(--color-black)')};
  font: var(--font-14);
  color: ${({ $isGrey }) => ($isGrey ? 'var(--color-black)' : 'var(--color-white)')};
  letter-spacing: 0.25px;
  text-transform: uppercase;
  height: 100%;
  line-height: 49px;
  text-align: center;
`;

export const TextWrap = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: ${({ $isGrey }) => ($isGrey ? 'var(--color-grey-ef)' : 'var(--color-black)')};
  padding: 0 15px;
  height: 100%;
`;

export const Text = styled.p`
  font: var(--font-14);
  color: ${({ $isBlack }) => ($isBlack ? 'var(--color-black)' : 'var(--color-white)')};
  letter-spacing: 0.25px;
  text-transform: uppercase;
`;

export const IconWrap = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  transition: transform 0.3s;
  transform: rotate(${({ $isOpen }) => ($isOpen ? -90 : 0)}deg);
  & > svg {
    fill: ${({ $isBlack }) => ($isBlack ? 'var(--color-black)' : 'var(--color-white)')};
  }
  @media (hover) {
    ${TitleWrap}:hover & {
      transform: rotate(-90deg);
    }
  }
`;

export const Container = styled(motion.section).attrs(({ $isOpen }) => ({
  animate: { height: $isOpen ? 'auto' : 0 },
  transition: { duration: 0.3 },
  initial: false,
}))`
  height: auto;
  overflow: hidden;
`;
