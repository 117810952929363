import { useRef, useCallback, useEffect, useMemo } from 'react';
import { pathOr } from 'ramda';

import useAsyncState from 'utils/useAsyncState';

export const useRadioActions = (audioRef) => {
  const timer = useRef();
  const [isPlay, setIsPlay] = useAsyncState(false);
  const [broadcast, setBroadcast] = useAsyncState({});

  const handleClick = useCallback(async () => {
    if (!audioRef.current) return;

    const { paused } = audioRef.current;

    try {
      if (paused) {
        await audioRef.current.play();
      } else {
        audioRef.current.pause();
      }
    } catch (e) {
      console.log(e); // eslint-disable-line no-console
    }
  }, [audioRef]);
  const handleAnimation = useCallback(
    ({ width }) => {
      if (!isPlay && width === 0) setIsPlay(false);
    },
    [isPlay, setIsPlay]
  );
  const onPlay = useCallback(() => setIsPlay(true), [setIsPlay]);
  const onPause = useCallback(() => setIsPlay(false), [setIsPlay]);

  const loadBroadcast = useCallback(async () => {
    try {
      const data = await fetch(process.env.REACT_APP_RADIO_BROADCAST).then((res) => {
        if (res.status >= 200 && res.status < 300) return res.json();

        throw Error('Error');
      });
      setBroadcast(data);
    } catch (e) {
      console.error(e); // eslint-disable-line no-console
    }

    clearTimeout(timer.current);
    timer.current = setTimeout(() => {
      loadBroadcast();
    }, 60000);
  }, [setBroadcast]);

  useEffect(() => {
    loadBroadcast();

    return () => clearTimeout(timer.current);
  }, [loadBroadcast]);

  useEffect(() => {
    if (!broadcast.is_live) setIsPlay(false);
  }, [broadcast.is_live, setIsPlay]);

  return {
    enabled: Boolean(broadcast.is_live),
    track: useMemo(
      () => pathOr(pathOr('-', ['current_broadcast', 'title'], broadcast), ['events', 0, 'title'], broadcast),
      [broadcast]
    ),
    isPlay,
    url: useMemo(
      () =>
        pathOr(
          broadcast.live_stream_url || process.env.REACT_APP_RADIO_URL,
          ['current_broadcast', 'streams', 'progressive', 'url'],
          broadcast
        ),
      [broadcast]
    ),
    mimeType: useMemo(
      () => pathOr('audio/mp3', ['current_broadcast', 'streams', 'progressive', 'mime_type'], broadcast),
      [broadcast]
    ),
    handleClick,
    handleAnimation,
    onPlay,
    onPause,
  };
};
