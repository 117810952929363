import { useMemo } from 'react';
import { useQuery } from '@apollo/client';

import { GET_IMPRINT_PAGE_DATA } from 'utils/gqls';

export const useImprintPageData = () => {
  const { loading, error, data = {} } = useQuery(GET_IMPRINT_PAGE_DATA);

  return useMemo(() => {
    if (!data?.page?.imprint) return { loading, error: error && (error.message || 'Oops, error occurred...') };

    const { participants, groups, coordination, design } = data.page.imprint;

    return {
      loading,
      error: error && (error.message || 'Oops, error occurred...'),
      participants,
      groups,
      coordination,
      design,
    };
  }, [data.page, error, loading]);
};
