import styled from 'styled-components';
import { motion } from 'framer-motion';

export const Container = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 1fr;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 48px;
  width: auto;
  background-color: var(--color-grey-ef);
  border-radius: 24px;
  padding: 1px 17px 0;
  overflow: hidden;
`;

export const Text = styled(motion.p).attrs(() => ({
  variants: {
    enter: {
      y: 90,
      opacity: 0,
    },
    center: {
      y: 0,
      opacity: 1,
    },
    exit: {
      y: -90,
      opacity: 0,
    },
  },
  transition: { duration: 0.5, delay: 2 },
}))`
  grid-column: 1/2;
  grid-row: 1/2;
  font: var(--font-14);
  letter-spacing: 0.25px;
  color: ${({ $color }) => $color};
  text-transform: uppercase;
  text-align: center;
`;

export const Span = styled.span`
  grid-column: 1/2;
  grid-row: 1/2;
  font: var(--font-14);
  letter-spacing: 0.25px;
  color: transparent;
  text-transform: uppercase;
`;
