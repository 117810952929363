/* eslint-disable react/no-array-index-key */
import React, { useContext, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams, Navigate } from 'react-router-dom';

import { AppContext } from 'utils/contexts';
import { EN } from 'locales';
import PageTitle from 'components/PageTitle';

import { useMainClick } from './hooks';
import Media from './Media';
import ShareButton from './ShareButton';
import {
  Container,
  ContentWrap,
  PillWrap,
  Pill,
  DescriptionWrap,
  ExtraWrap,
  SmallText,
  TextLink,
  SectionName,
  Section,
  SectionItem,
  LinkWrap,
  LinkPill,
  ExtraText,
} from './styles';

const Event = () => {
  const { t, i18n } = useTranslation();
  const [{ filteredFutureEvents, filteredPastEvents, isUpdated }] = useContext(AppContext);
  const eventsObject = useMemo(
    () =>
      [...filteredFutureEvents, ...filteredPastEvents].reduce((acc, item) => {
        acc[item.id] = item;

        return acc;
      }, {}),
    [filteredFutureEvents, filteredPastEvents]
  );
  const { id } = useParams();
  const {
    organizer,
    type,
    location,
    description,
    extraText,
    program,
    lineUp,
    links,
    index,
    video,
    image,
    title,
    isHiddenTitle,
    titleColor,
  } = eventsObject[id] || {};

  useMainClick();

  if (isUpdated && !eventsObject[id]) return <Navigate to="/calendar" />;

  return (
    <PageTitle>
      {Boolean(eventsObject[id]) && (
        <Media index={index} video={video} image={image} title={title} isHiddenTitle={isHiddenTitle} titleColor={titleColor} />
      )}
      <Container>
        <ContentWrap id="event_page_scroll_content">
          <PillWrap>
            <Pill items={type} />
            <Pill items={location} />
          </PillWrap>
          {Boolean(description && description[i18n.language]) && (
            <DescriptionWrap dangerouslySetInnerHTML={{ __html: description[i18n.language] }} />
          )}
          {Boolean(extraText && extraText[i18n.language]) && (
            <ExtraWrap dangerouslySetInnerHTML={{ __html: extraText[i18n.language] }} />
          )}
          {Boolean(program?.length) && (
            <>
              <SectionName>{t('Program')}</SectionName>
              <Section>
                {program.map(({ timeFrom: prTimeFrom, timeTo: prTimeTo, name: prName }, i) => (
                  <SectionItem key={i}>
                    <SmallText $upper>{`${prTimeFrom}–${prTimeTo} H`}</SmallText>
                    <SmallText $upper>{prName}</SmallText>
                  </SectionItem>
                ))}
              </Section>
            </>
          )}
          {Boolean(lineUp && lineUp?.items?.length) && (
            <>
              <SectionName>{(i18n.language === EN && lineUp.nameEn) || lineUp.name || 'N/A'}</SectionName>
              <Section>
                {lineUp.items.map(({ timeFrom: eTimeFrom, timeTo: eTimeTo, link: eLink, text: eText }, i) => (
                  <SectionItem key={i}>
                    <SmallText $upper>{`${eTimeFrom}–${eTimeTo} H`}</SmallText>
                    {eText && !eLink && <SmallText $upper>{eText}</SmallText>}
                    {eLink && (
                      <TextLink href={eLink.url} target="_blank" rel="noopener noreferrer" $upper>
                        {eLink.title}
                      </TextLink>
                    )}
                  </SectionItem>
                ))}
              </Section>
            </>
          )}
          {Boolean(organizer) && <ExtraText>{t('organizer', { name: organizer })}</ExtraText>}
          <LinkWrap>
            {Boolean(links?.length) &&
              links.map(({ link }) => (
                <LinkPill key={link.url} href={link.url} target="_blank" rel="noopener noreferrer">
                  {link.title}
                </LinkPill>
              ))}
            {isUpdated && <ShareButton title={title} />}
          </LinkWrap>
        </ContentWrap>
      </Container>
    </PageTitle>
  );
};

export default Event;
