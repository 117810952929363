import styled from 'styled-components';

export const Button = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 48px;
  height: 48px;
  font: var(--font-14);
  letter-spacing: 0.25px;
  text-transform: uppercase;
  color: var(--color-black);
  background-color: var(--color-grey-ef);
  &:active {
    color: var(--color-grey-a7);
  }
  @media (hover) {
    &:hover:enabled {
      color: var(--color-grey-a7);
    }
  }
`;
