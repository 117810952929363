import React, { useCallback, Fragment } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import useAsyncState from 'utils/useAsyncState';
import PageTitle from 'components/PageTitle';

import { useSpacePageData, useSubmitForm } from './hooks';
import { INPUTS } from './consts';
import Thanks from './Thanks';
import Checkbox from './Checkbox';
import { Container, TextWrap, Form, Input, CheckboxWrap, Button, ErrMessage } from './styles';

const Space = () => {
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();

  const { loading, error, description, /* uploadTextBefore, uploadTextAfter, */ thanksMessage } = useSpacePageData(i18n.language);
  const [showThanks, setShowThanks] = useAsyncState(false);

  const onSuccess = useCallback(() => setShowThanks(true), [setShowThanks]);
  const { /* fileName, */ onChange, onSubmit, loading: submitLoading, error: submitError } = useSubmitForm(onSuccess);

  const onClose = useCallback(() => navigate('/'), [navigate]);

  if (error) return <ErrMessage>{error}</ErrMessage>;

  return (
    <PageTitle loading={loading}>
      {showThanks ? (
        <Thanks onClose={onClose} text={thanksMessage} />
      ) : (
        <Container>
          <TextWrap dangerouslySetInnerHTML={{ __html: description }} />
          <Form onSubmit={onSubmit} onChange={onChange}>
            {INPUTS.map(({ kind, type, name, sheetId, items }) => (
              <Fragment key={name}>
                {kind === 'input' && (
                  <Input
                    type={type}
                    name={name}
                    placeholder={t(sheetId)}
                    disabled={submitLoading}
                    {...(type === 'number' && { min: 0 })}
                  />
                )}
                {kind === 'checkboxes' && (
                  <CheckboxWrap $before={t(sheetId)}>
                    {items.map(({ id, label }) => (
                      <Checkbox key={id} name={id} label={t(label)} disabled={submitLoading} />
                    ))}
                  </CheckboxWrap>
                )}
              </Fragment>
            ))}
            {submitError && <ErrMessage $small>{submitError}</ErrMessage>}
            <Button type="submit">{t('app:Send')}</Button>
          </Form>
        </Container>
      )}
    </PageTitle>
  );
};

export default Space;
