import React, { useState, useCallback, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

import { ROUTES } from 'utils/consts';

import MenuIcon from './MenuIcon';
import CloseIcon from './CloseIcon';
import { Container, Button, ButtonText, IconWrap, Nav, Link } from './styles';

const Menu = ({ pathname }) => {
  const { t } = useTranslation();
  const [isOpen, setIsOpen] = useState(false);

  const toggle = useCallback(() => setIsOpen(($) => !$), []);
  const open = useCallback(() => setIsOpen(true), []);
  const close = useCallback(() => setIsOpen(false), []);
  const handleBodyClick = useCallback(
    ({ target }) => {
      if (!target.closest('header')) close();
    },
    [close]
  );

  useEffect(() => {
    if (!isOpen) return () => null;

    window.document.addEventListener('touchstart', handleBodyClick);

    return () => window.document.removeEventListener('touchstart', handleBodyClick);
  }, [handleBodyClick, isOpen]);

  useEffect(() => {
    close();
  }, [pathname, close]);

  return (
    <Container onHoverStart={open} onHoverEnd={close}>
      <Button type="button" onTouchEnd={toggle} aria-label="Menu">
        <ButtonText>{t('Menu')}</ButtonText>
        <IconWrap $isOpen={isOpen}>{isOpen ? <CloseIcon /> : <MenuIcon />}</IconWrap>
      </Button>
      <Nav $isOpen={isOpen}>
        {ROUTES.map(({ to, name }) => (
          <Link key={to} to={to} aria-label={`${name} Page`}>
            {t(name)}
          </Link>
        ))}
      </Nav>
    </Container>
  );
};

Menu.propTypes = { pathname: PropTypes.string.isRequired };

export default Menu;
