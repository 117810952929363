import { createGlobalStyle } from 'styled-components';

import groteskWoff from 'assets/fonts/ABCMonumentGrotesk-Medium.woff';
import groteskWoff2 from 'assets/fonts/ABCMonumentGrotesk-Medium.woff2';

export default createGlobalStyle`
  @font-face {
    font-family: ABC Monument Grotesk;
    font-display: swap;
    src: url(${groteskWoff2}) format("woff2"), url(${groteskWoff}) format("woff");
  }
  html {
    display: flex;
    flex-direction: column;
    height: 100%;
    width: 100%;
    min-width: 350px;
    position: fixed;
    @media (max-width: 349px) {
      position: static;
    }
  }
  body, #root {
    flex: 1 1 auto;
    display: flex;
    flex-direction: column;
    min-height: 100%;
    margin: 0;
    padding: 0;
  }
  main {
    flex: 1 1 auto;
    display: flex;
    flex-direction: column;
    min-height: 100%;
    overflow: auto;
    &::-webkit-scrollbar {
      display: none;
    }
    -ms-overflow-style: none;
    scrollbar-width: none;
  }
  button, select {
    border: none;
    border-radius: 0;
    background: none;
    outline: none;
    cursor: pointer;
  }
  input, textarea {
    outline: none;
    border-radius: 0;
    border: none;
    background: none;
    padding: 0;
  }
  * {
    font: normal normal 500 14px/16px ABC Monument Grotesk;
    color: var(--color-black);
    margin: 0;
    padding: 0;
    appearance: none;
    box-sizing: border-box;
    -webkit-tap-highlight-color: transparent;
    -webkit-overflow-scrolling: touch;
    -webkit-appearance: none;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }
  &:root {
    --color-white: #fff;
    --color-black: #000;
    --color-red: #f00;
    --color-blue: #00f;
    --color-green: #45ff00;
    --color-purple: #eae9ef;

    --color-grey-fa: #fafafa;
    --color-grey-ef: #efefef;
    --color-grey-c1: #c1c1c1;
    --color-grey-a7: #a7a7a7;
    --color-grey-99: #999;

    --font-151: normal normal 500 151px/251px ABC Monument Grotesk;
    --font-100: normal normal 500 100px/100px ABC Monument Grotesk;
    --font-80: normal normal 500 80px/80px ABC Monument Grotesk;
    --font-67: normal normal 500 67px/93px ABC Monument Grotesk;
    --font-50: normal normal 500 50px/50px ABC Monument Grotesk;
    --font-38: normal normal 500 38px/36px ABC Monument Grotesk;
    --font-32: normal normal 500 32px/32px ABC Monument Grotesk;
    --font-22: normal normal 500 22px/22px ABC Monument Grotesk;
    --font-19: normal normal 500 18px/19px ABC Monument Grotesk;
    --font-16: normal normal 500 16px/16px ABC Monument Grotesk;
    --font-14: normal normal 500 14px/19px ABC Monument Grotesk;
    --font-10: normal normal 500 10px/12px ABC Monument Grotesk;
  }
`;
