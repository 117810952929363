import React, { useMemo } from 'react';
import Player from 'react-player';
import PropTypes from 'prop-types';

import { PLAYER_CONFIG } from 'utils/consts';

import { checkVisible } from './utils';
import { Container, Title, VideoWrap } from './styles';

const Card = ({ indexValue, index, length, video, image, title, isHiddenTitle, titleColor }) => {
  const [visible, playing] = useMemo(() => checkVisible(indexValue.get(), index, length), [index, indexValue, length]);

  if (!visible || playing) return null;

  return (
    <Container $imageUrl={video ? null : image} $isBlur>
      {video && (
        <Player
          url={video}
          playing={playing}
          volume={0}
          wrapper={VideoWrap}
          width="auto"
          height="100%"
          playIcon={null}
          poster={null}
          config={PLAYER_CONFIG}
          playsinline
          loop
          muted
        />
      )}
      {!isHiddenTitle && <Title $color={titleColor}>{title}</Title>}
    </Container>
  );
};

Card.defaultProps = { video: null, image: null };
Card.propTypes = {
  indexValue: PropTypes.shape({
    get: PropTypes.func.isRequired,
    set: PropTypes.func.isRequired,
    onChange: PropTypes.func.isRequired,
  }).isRequired,
  index: PropTypes.number.isRequired,
  length: PropTypes.number.isRequired,
  video: PropTypes.string,
  image: PropTypes.string,
  title: PropTypes.string.isRequired,
  isHiddenTitle: PropTypes.bool.isRequired,
  titleColor: PropTypes.string.isRequired,
};

export default Card;
