import styled from 'styled-components';

import { Section } from '../styles';

export const Container = styled(Section)`
  background-color: var(--color-black);
  padding: 63px 0;
  @media (max-height: 959px), (max-width: 1009px) {
    background-color: var(--color-white);
    padding: 15px 0 0;
  }
`;

export const Wrapper = styled.div`
  display: grid;
  grid-template-columns: auto auto;
  grid-template-rows: repeat(3, auto);
  gap: 10vh 0;
  justify-content: space-around;
  align-items: center;
  width: 100%;
  background-color: var(--color-black);
  @media (max-height: 959px), (max-width: 1009px) {
    padding: 10vh 0;
  }
`;

export const Link = styled.a`
  display: flex;
  justify-content: center;
  align-items: center;
  height: auto;
  max-height: 210px;
  width: 33vw;
  max-width: 750px;
  border-radius: 105px;
  background-color: var(--color-white);
  font: var(--font-67);
  font-size: calc(100vw / 29);
  line-height: 3;
  letter-spacing: 0.67px;
  color: var(--color-black);
  text-transform: uppercase;
  text-decoration: none;
  transition-property: background-color, color;
  transition-duration: 0.3s;
  &:active {
    background-color: var(--color-grey-c1);
  }
  @media (hover) {
    &:hover {
      background-color: var(--color-grey-c1);
    }
  }
  @media (min-width: 1920px) {
    font: var(--font-67);
    line-height: 3;
  }
`;
