import React, { useContext, useMemo, useCallback, useEffect } from 'react';
import { useTransform, useMotionValue } from 'framer-motion';

import { AppContext } from 'utils/contexts';

import Card from './Card';
import { Container, Wrapper, Figure, CardWrap } from './styles';

const Carousel = () => {
  const [{ filteredPastEvents, filteredFutureEvents, indexValue }] = useContext(AppContext);
  const items = useMemo(() => [...filteredFutureEvents, ...filteredPastEvents], [filteredPastEvents, filteredFutureEvents]);
  const motionValue = useMotionValue(indexValue.get());
  const rotateX = useTransform(motionValue, ($) => {
    if (window.innerWidth > window.innerHeight) return '0rad';

    return `${$ * ((2 * Math.PI) / items.length)}rad`;
  });
  const rotateY = useTransform(motionValue, ($) => {
    if (window.innerWidth <= window.innerHeight) return '0rad';

    return `${$ * ((-2 * Math.PI) / items.length)}rad`;
  });
  const translateZ = useTransform(motionValue, () => {
    if (window.innerWidth < 700 || window.innerHeight < 700) {
      return `${-390 / (2 * Math.tan(Math.PI / Math.max(2, items.length)))}px`;
    }
    return `${-670 / (2 * Math.tan(Math.PI / Math.max(2, items.length)))}px`;
  });

  const onDragEnd = useCallback(
    (e, { offset, velocity }) => {
      const range = window.innerWidth > window.innerHeight ? offset.x : -offset.y;
      const speed = window.innerWidth > window.innerHeight ? velocity.x : velocity.y;
      const swipe = Math.abs(range) * speed;
      const current = motionValue.get();

      if (swipe < -800000) {
        motionValue.set(current + 3);
      } else if (swipe > 800000) {
        motionValue.set(current - 3);
      } else if (swipe < -400000) {
        motionValue.set(current + 2);
      } else if (swipe > 400000) {
        motionValue.set(current - 2);
      } else if (swipe < -1000) {
        motionValue.set(current + 1);
      } else if (swipe > 1000) {
        motionValue.set(current - 1);
      }
    },
    [motionValue]
  );

  const onKeyDown = useCallback(
    ({ keyCode }) => {
      const codes = window.innerWidth > window.innerHeight ? [37, 39] : [38, 40];

      if (!codes.includes(keyCode)) return;

      const current = motionValue.get();

      if (keyCode === 37 || keyCode === 38) motionValue.set(current - 1);
      if (keyCode === 39 || keyCode === 40) motionValue.set(current + 1);
    },
    [motionValue]
  );

  useEffect(
    () =>
      motionValue.onChange(($) => {
        indexValue.set($ - Math.floor($ / items.length) * items.length);
      }),
    [indexValue, items.length, motionValue]
  );

  useEffect(() => {
    window.addEventListener('keydown', onKeyDown);

    return () => window.removeEventListener('keydown', onKeyDown);
  }, [onKeyDown]);

  return (
    <Container onPanEnd={onDragEnd}>
      <Wrapper>
        <Figure style={{ rotateX, rotateY, translateZ }}>
          {items.map(({ id, index, title, isHiddenTitle, titleColor, date, type, timeFrom, timeTo, image, video }) => (
            <CardWrap key={id} $index={index} $length={Math.max(2, items.length)}>
              <Card
                indexValue={motionValue}
                id={id}
                index={index}
                length={items.length}
                date={date}
                type={type}
                title={title}
                isHiddenTitle={isHiddenTitle}
                titleColor={titleColor}
                timeFrom={timeFrom}
                timeTo={timeTo}
                video={video}
                image={image}
              />
            </CardWrap>
          ))}
        </Figure>
      </Wrapper>
    </Container>
  );
};

export default Carousel;
