import { useMemo, useCallback } from 'react';
import { useQuery } from '@apollo/client';
import { pathOr, pipe, map, filter, join } from 'ramda';

import { GET_SPACE_PAGE_DATA } from 'utils/gqls';
import useAsyncState from 'utils/useAsyncState';
import { EN } from 'locales';

import { INPUTS } from './consts';

export const useSpacePageData = (language) => {
  const { loading, error, data = {} } = useQuery(GET_SPACE_PAGE_DATA);

  return useMemo(() => {
    if (!data?.page?.space) return { loading, error: error && (error.message || 'Oops, error occurred...') };

    const {
      description,
      descriptionEn,
      uploadTextBefore,
      uploadTextBeforeEn,
      uploadTextAfter,
      uploadTextAfterEn,
      thanksMessage,
      thanksMessageEn,
    } = data.page.space;

    return {
      loading,
      error: error && (error.message || 'Oops, error occurred...'),
      description: (language === EN ? descriptionEn : description).replace(/\n/g, '<br />'),
      uploadTextBefore: language === EN ? uploadTextBeforeEn : uploadTextBefore,
      uploadTextAfter: language === EN ? uploadTextAfterEn : uploadTextAfter,
      thanksMessage: language === EN ? thanksMessageEn : thanksMessage,
    };
  }, [data.page, error, language, loading]);
};

export const useSubmitForm = (onSuccess) => {
  const [fileName, setFileName] = useAsyncState(null);
  const [loading, setLoading] = useAsyncState(false);
  const [error, setError] = useAsyncState(null);

  const onChange = useCallback(
    ({ target }) => {
      const { name, files = {} } = target;

      if (name === 'file' && files['0']?.name) setFileName(files['0'].name);
      setError(null);
    },
    [setError, setFileName]
  );

  const onSubmit = useCallback(
    async (e) => {
      e.preventDefault();
      setLoading(true);
      setError(null);

      try {
        const data = INPUTS.reduce((acc, { kind, name, sheetId, items }) => {
          if (kind === 'input') {
            acc[sheetId] = pathOr('', ['currentTarget', name, 'value'], e);
          }
          if (kind === 'checkboxes') {
            acc[sheetId] = pipe(
              map(({ id, label }) => (pathOr(null, ['currentTarget', id, 'checked'], e) ? label : '')),
              filter(Boolean),
              join(', ')
            )(items);
          }

          return acc;
        }, {});

        const { status } = await fetch(process.env.REACT_APP_GOOGLE_SHEET_URL, {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify(data),
        });

        if (!(status >= 200 && status < 300)) throw Error('Oops, error occurred while sending data.');

        onSuccess();

        if (e.currentTarget?.reset) e.currentTarget.reset(null);
      } catch (err) {
        setError(err?.message || err);
        console.error(err); // eslint-disable-line no-console
      }

      setLoading(false);
    },
    [onSuccess, setError, setLoading]
  );

  return {
    fileName,
    onChange,
    onSubmit,
    loading,
    error,
  };
};
