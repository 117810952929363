import React, { useCallback, useLayoutEffect } from 'react';
import { useMotionValue } from 'framer-motion';
import Player from 'react-player';
import PropTypes from 'prop-types';

import { PLAYER_CONFIG } from 'utils/consts';

import { Container, Card, VideoWrap, Title } from './styles';

const AnimatedCard = ({ video, image, titleColor, title, isHiddenTitle }) => {
  const scale = useMotionValue(1);
  const filter = useMotionValue('blur(0)');
  const opacity = useMotionValue(1);
  const handleScroll = useCallback(
    ({ currentTarget }) => {
      const range = Math.min(currentTarget.scrollTop / Math.min(500, window.innerWidth - 100), 1);

      scale.set(1 - range * 0.5);
      filter.set(`blur(${25 * range}px)`);
      opacity.set(1 - range);
    },
    [filter, scale, opacity]
  );

  useLayoutEffect(() => {
    const main = document.querySelector('main');

    if (!main) return () => null;

    main.addEventListener('scroll', handleScroll);

    return () => main.removeEventListener('scroll', handleScroll);
  }, [handleScroll]);

  return (
    <>
      <Container $isFixed>
        <Card $imageUrl={video ? null : image} style={{ scale, filter, opacity }}>
          {video && (
            <Player
              url={video}
              volume={0}
              wrapper={VideoWrap}
              width="auto"
              height="100%"
              playIcon={null}
              poster={null}
              config={PLAYER_CONFIG}
              playsinline
              loop
              muted
            />
          )}
          {!isHiddenTitle && <Title $color={titleColor}>{title}</Title>}
        </Card>
      </Container>
      <Container />
    </>
  );
};

AnimatedCard.defaultProps = { video: null, image: null };
AnimatedCard.propTypes = {
  video: PropTypes.string,
  image: PropTypes.string,
  title: PropTypes.string.isRequired,
  titleColor: PropTypes.string.isRequired,
  isHiddenTitle: PropTypes.bool.isRequired,
};

export default AnimatedCard;
