import styled from 'styled-components';
import { motion } from 'framer-motion';

export const Container = styled(motion.div)`
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: var(--color-white);
  color: var(--color-black);
  z-index: 1000;
  -webkit-transform: translate3d(0, 0, 0.1px);
  opacity: 0.96;
`;

export const Title = styled.h1`
  font: var(--font-151);
  line-height: 1.25;
  font-size: 8vw;
  letter-spacing: -0.236vw;
  color: inherit;
  text-transform: uppercase;
  text-align: center;
  z-index: 1;
  @media (min-width: 1920px) {
    font: var(--font-151);
    letter-spacing: -4.53px;
  }
  @media (max-width: 400px) {
    font: var(--font-32);
    letter-spacing: 0;
  }
`;
