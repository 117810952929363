import styled from 'styled-components';
import { Link } from 'react-router-dom';

import AnimatedPill from 'components/AnimatedPill';

export const Container = styled(Link)`
  display: grid;
  grid-template-columns: auto 1fr;
  grid-template-rows: 48px;
  align-items: center;
  width: 100%;
  border-top: 1px solid var(--color-grey-a7);
  background-color: var(--color-white);
  text-align: left;
  text-decoration: none;
  color: var(--color-black);
  z-index: 0;
  &:first-of-type {
    border-top: 2px solid var(--color-white);
  }
  @media (hover) {
    &:hover {
      background-color: ${({ $isPrevious }) => ($isPrevious ? 'var(--color-black)' : 'var(--color-grey-ef)')};
      color: ${({ $isPrevious }) => ($isPrevious ? 'var(--color-white)' : 'var(--color-black)')};
      & > div > svg {
        fill: ${({ $isPrevious }) => ($isPrevious ? 'var(--color-white)' : 'var(--color-black)')};
      }
    }
  }
`;

export const CursorBox = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: ${({ $isActive }) => ($isActive ? 48 : 0)}px;
  overflow: hidden;
  background-color: ${({ $isPrevious }) => ($isPrevious ? 'var(--color-black)' : 'var(--color-grey-ef)')};
  transition: width 0.3s;
  & > svg {
    fill: ${({ $isPrevious }) => ($isPrevious ? 'var(--color-white)' : 'var(--color-black)')};
  }
`;

export const Wrapper = styled.div`
  display: grid;
  grid-template-columns: ${({ $isActive }) => ($isActive ? 100 : 149)}px 1fr 120px 145px auto;
  column-gap: 15px;
  height: 100%;
  align-items: center;
  padding: 0 15px;
  color: inherit;
  @media (max-width: 991px) {
    grid-template-columns: 100px 1fr auto;
    column-gap: 8px;
    padding: 0 8px 0 15px;
    & > *:nth-child(n + 4) {
      display: none;
    }
  }
`;

export const Text = styled.p`
  width: 100%;
  font: var(--font-14);
  letter-spacing: 0.25px;
  color: inherit;
  text-transform: uppercase;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
`;

export const Pill = styled(AnimatedPill)`
  height: 40px;
  border-radius: 20px;
  @media (hover) {
    ${Container}:hover & {
      background-color: var(--color-white);
    }
  }
`;
