import styled from 'styled-components';
import { Link } from 'react-router-dom';

export const Container = styled.header`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 49px;
  z-index: 100;
  position: fixed;
  top: 0;
`;

export const Button = styled(Link)`
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: var(--color-grey-ef);
  width: 49px;
  height: 100%;
  @media (hover) {
    &:hover > svg {
      fill: var(--color-grey-a7);
    }
  }
`;
