import React from 'react';
import { useMatch } from 'react-router-dom';
import PropTypes from 'prop-types';

import { AppContext } from 'utils/contexts';

import { useServerData } from './hooks';
import Header from './Header';
import EventHeader from './EventHeader';
import ImprintHeader from './ImprintHeader';
import { Message } from './styles';

const Layout = ({ children }) => {
  const [context, error] = useServerData();
  const eventPage = useMatch('/event/:id');
  const imprintPage = useMatch('/imprint');

  return (
    <AppContext.Provider value={context}>
      {error ? (
        <Message>{error}</Message>
      ) : (
        <>
          {Boolean(eventPage) && <EventHeader id={eventPage.params.id} />}
          {Boolean(imprintPage) && <ImprintHeader />}
          <Header isHidden={Boolean(eventPage || imprintPage)} />
          <main>{children}</main>
        </>
      )}
    </AppContext.Provider>
  );
};

Layout.propTypes = { children: PropTypes.node.isRequired };

export default Layout;
