import React, { useCallback, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

import useAsyncState from 'utils/useAsyncState';

import { Button } from './styles';

const ShareButton = ({ title }) => {
  const { t } = useTranslation();
  const [isCopied, setIsCopied] = useAsyncState(false);

  const copyLink = useCallback(async () => {
    try {
      if (typeof window.navigator.share === 'function') {
        await window.navigator.share({
          url: window.location.href,
          title: 'Summer Camp 24',
          text: title,
        });
      } else {
        await window.navigator.clipboard.writeText(window.location.href);
        setIsCopied(true);
      }
    } catch (e) {
      console.error(e); // eslint-disable-line no-console
    }
  }, [setIsCopied, title]);

  useEffect(() => {
    if (!isCopied) return () => null;
    const timer = setTimeout(() => setIsCopied(false), 2000);

    return () => clearTimeout(timer);
  }, [isCopied, setIsCopied]);

  if (!(window.navigator?.share || window.navigator?.clipboard?.writeText)) return null;

  return (
    <Button type="button" onClick={copyLink}>
      {t(isCopied ? 'Copied' : 'Share')}
    </Button>
  );
};

ShareButton.defaultProps = { title: '' };
ShareButton.propTypes = { title: PropTypes.string };

export default ShareButton;
