import React from 'react';

const MenuIcon = () => (
  <svg height="48" width="48" viewBox="0 0 150 150">
    <rect x="38" y="42.32" width="74" height="4.68" />
    <rect x="38" y="72.66" width="74" height="4.68" />
    <rect x="38" y="103" width="74" height="4.68" />
  </svg>
);

export default MenuIcon;
