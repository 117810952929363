import React from 'react';

import CloseIcon from 'assets/svg/CloseIcon';
import Language from '../Language';

import { Container, Button } from './styles';

const ImprintHeader = () => (
  <Container>
    <Button to="/" aria-label="Home page">
      <CloseIcon />
    </Button>
    <Language />
  </Container>
);

export default ImprintHeader;
