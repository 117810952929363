import styled from 'styled-components';

export const Container = styled.div`
  flex: 1 1 auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  padding-top: 60px;
`;

export const TextWrap = styled.div`
  font: var(--font-19);
  letter-spacing: 0px;
  color: var(--color-black);
  max-width: 660px;
  & p {
    font: var(--font-19);
    letter-spacing: 0px;
    color: var(--color-black);
    padding: 0 15px;
    margin-bottom: 1em;
  }
  & a {
    font: var(--font-19);
    letter-spacing: 0px;
    color: var(--color-black);
  }
`;

export const Form = styled.form`
  display: flex;
  flex-direction: column;
  align-items: center;
  row-gap: 1px;
  width: 100%;
  max-width: 630px;
`;

export const Input = styled.input`
  width: 100%;
  height: 48px;
  background-color: var(--color-grey-ef);
  font: var(--font-14);
  letter-spacing: 0.25px;
  color: var(--color-black);
  padding: 0 15px;
  &::placeholder {
    font: var(--font-14);
    letter-spacing: 0.25px;
    color: var(--color-grey-a7);
  }
`;

export const CheckboxWrap = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 15px;
  width: 100%;
  background-color: var(--color-grey-ef);
  padding: 15px;
  &:before {
    content: '${({ $before }) => $before}';
    font: var(--font-14);
    letter-spacing: 0.25px;
    text-transform: uppercase;
    color: var(--color-black);
  }
`;

export const Button = styled.button`
  width: fit-content;
  height: 42px;
  border-radius: 21px;
  font: var(--font-14);
  letter-spacing: 0.25px;
  text-transform: uppercase;
  color: var(--color-white);
  background-color: var(--color-black);
  padding: 0 17px;
  margin: 20px;
  @media (hover) {
    &:hover {
      color: var(--color-grey-a7);
    }
  }
`;

export const ErrMessage = styled.p`
  font: ${({ $small }) => ($small ? 'var(--font-14)' : 'var(--font-32)')};
  color: var(--color-red);
  text-align: center;
  margin: ${({ $small }) => ($small ? 0 : '1em')};
`;
