import { useMemo } from 'react';
import { useQuery } from '@apollo/client';

import { GET_ABOUT_PAGE_DATA } from 'utils/gqls';
import { EN } from 'locales';
import { map, applySpec, path } from 'ramda';

export const useAboutPageData = (language) => {
  const { loading, error, data = {} } = useQuery(GET_ABOUT_PAGE_DATA);

  return useMemo(() => {
    if (!data?.page?.about) return { loading, error: error && (error.message || 'Oops, error occurred...') };

    const { openCall, openCallEn, general, generalEn, manifest, manifestEn, contact, contactEn, links } = data.page.about;

    return {
      loading,
      error: error && (error.message || 'Oops, error occurred...'),
      openCall: language === EN ? openCallEn : openCall,
      general: language === EN ? generalEn : general,
      manifest: language === EN ? manifestEn : manifest,
      contact: language === EN ? contactEn : contact,
      links: map(
        applySpec({
          url: path(['link', 'url']),
          title: path(['link', 'title']),
          target: path(['link', 'target']),
        })
      )(links),
    };
  }, [data.page, error, language, loading]);
};
