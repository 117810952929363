/* eslint-disable react/no-array-index-key */
import React, { useState, useMemo, useCallback, useEffect } from 'react';
import { AnimatePresence } from 'framer-motion';
import PropTypes from 'prop-types';

import { Container, Text, Span } from './styles';

const AnimatedPill = ({ items, color, className }) => {
  const [index, setIndex] = useState(0);
  const maxContent = useMemo(() => (items?.length ? items.sort((a, b) => b.length - a.length)[0] : ''), [items]);
  const onAnimationComplete = useCallback(
    (pose) => {
      if (pose !== 'center' || !items?.length) return;

      setIndex(($) => ($ >= items.length - 1 ? 0 : $ + 1));
    },
    [items]
  );

  useEffect(() => {
    if (items?.length && items.length > 1) {
      setIndex(1);
    }
  }, [items.length]);

  if (!items?.length) return <div />;

  return (
    <Container className={className}>
      <AnimatePresence initial={false}>
        <Text key={index} initial="enter" animate="center" exit="exit" onAnimationComplete={onAnimationComplete} $color={color}>
          {items[index]}
        </Text>
      </AnimatePresence>
      <Span>{maxContent}</Span>
    </Container>
  );
};

AnimatedPill.defaultProps = {
  items: [],
  color: 'var(--color-black)',
  className: null,
};
AnimatedPill.propTypes = {
  items: PropTypes.arrayOf(PropTypes.string.isRequired),
  color: PropTypes.string,
  className: PropTypes.string,
};

export default AnimatedPill;
