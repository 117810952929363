import styled from 'styled-components';
import { NavLink } from 'react-router-dom';

import desktop from 'assets/image/about_background_desktop.jpg';
import mobile from 'assets/image/about_background_mobile.jpg';
import { Section } from '../styles';

export const Container = styled(Section)`
  background-color: var(--color-white);
  background-image: url(${desktop});
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  padding: 63px 15px 40px;
  @media (max-height: 959px), (max-width: 1009px) {
    min-height: 100%;
  }
  @media (orientation: portrait) {
    background-image: url(${mobile});
  }
`;

export const Wrapper = styled.div`
  flex: 1 1 auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 20px;
`;

export const TextWrap = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  @media (orientation: portrait) {
    flex: 1 1 auto;
  }
`;

export const Text = styled.p`
  width: 100%;
  max-width: 990px;
  font: var(--font-100);
  color: var(--color-black);
  text-align: center;
  text-transform: uppercase;
  @media (max-width: 719px) {
    font: var(--font-50);
  }
`;

export const Br = styled.br`
  @media (min-width: 720px) {
    display: none;
  }
`;

export const Link = styled(NavLink)`
  display: flex;
  justify-content: center;
  align-items: center;
  width: fit-content;
  height: 42px;
  border-radius: 21px;
  font: var(--font-14);
  letter-spacing: 0.25px;
  text-transform: uppercase;
  text-decoration: none;
  color: var(--color-white);
  background-color: var(--color-black);
  transition: color 0.3s;
  padding: 0 17px;
  &:active,
  &.active {
    color: var(--color-grey-99);
  }
  @media (hover) {
    &:hover {
      color: var(--color-grey-99);
    }
  }
`;
