import styled from 'styled-components';

import AnimatedPill from 'components/AnimatedPill';

export const Container = styled.div`
  flex: 1 1 auto;
  display: flex;
  flex-direction: column;
  row-gap: 20px;
  width: 100%;
  max-width: 700px;
  min-height: calc((100% - 520px) / 2 + 520px);
  margin: 0 auto 0;
  z-index: 1;
  -webkit-transform: translate3d(0, 0, 0.1px);
  @media (max-height: 699px) {
    min-height: calc((100% - 340px) / 2 + 340px);
  }
  @media (orientation: portrait) {
    min-height: calc(100% - 55px);
  }
`;

export const ContentWrap = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 20px;
  width: 100%;
  padding-bottom: 15px;
`;

export const PillWrap = styled.div`
  display: none;
  flex-wrap: wrap;
  column-gap: 10px;
  align-items: center;
  margin: 5px 0 0;
  @media (max-width: 991px) {
    display: flex;
  }
  @media (max-width: 690px) {
    margin: 5px 10px 0;
  }
`;

export const Pill = styled(AnimatedPill)`
  height: 42px;
  border-radius: 21px;
`;

export const DescriptionWrap = styled.div`
  font: var(--font-19);
  letter-spacing: 0px;
  color: var(--color-black);
  & p {
    font: var(--font-19);
    letter-spacing: 0px;
    color: var(--color-black);
    margin-bottom: 1em;
  }
  & p:last-of-type {
    margin-bottom: 0;
  }
  & a {
    font: var(--font-19);
    letter-spacing: 0px;
    color: var(--color-black);
  }
  @media (max-width: 690px) {
    margin: 0 10px;
  }
`;

export const ExtraWrap = styled.div`
  border: 1px solid var(--color-black);
  padding: 10px;
  & p {
    font: var(--font-14);
    letter-spacing: 0.25px;
    color: var(--color-black);
  }
  & a {
    font: var(--font-14);
    letter-spacing: 0.25px;
    color: var(--color-black);
  }
  @media (max-width: 690px) {
    margin: 0 10px;
  }
`;

export const SmallText = styled.p`
  font: var(--font-14);
  letter-spacing: 0.25px;
  color: var(--color-black);
  text-transform: ${({ $upper }) => ($upper ? 'uppercase' : 'none')};
`;

export const TextLink = styled.a`
  font: var(--font-14);
  letter-spacing: 0.25px;
  color: var(--color-black);
  text-transform: ${({ $upper }) => ($upper ? 'uppercase' : 'none')};
`;

export const SectionName = styled.h3`
  font: var(--font-19);
  letter-spacing: 0px;
  color: var(--color-black);
  text-transform: uppercase;
  margin: 0;
  @media (max-width: 690px) {
    margin: 0 15px;
  }
`;

export const Section = styled.section`
  display: grid;
  grid-template-columns: 1fr;
  grid-auto-flow: row;
  row-gap: 2px;
  width: 100%;
`;

export const SectionItem = styled.div`
  display: grid;
  grid-template-columns: 100px 1fr;
  align-items: center;
  column-gap: 15px;
  padding: 0 15px;
  background-color: var(--color-grey-ef);
  height: 48px;
`;

export const LinkWrap = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  @media (max-width: 690px) {
    margin: 0 10px;
  }
`;

export const LinkPill = styled.a`
  display: flex;
  justify-content: center;
  align-items: center;
  width: fit-content;
  height: 42px;
  border: 1px solid var(--color-black);
  border-radius: 21px;
  font: var(--font-14);
  color: var(--color-black);
  letter-spacing: 0.25px;
  text-transform: uppercase;
  text-decoration: none;
  padding: 1px 17px 0;
  @media (hover) {
    &:hover {
      background-color: var(--color-black);
      color: var(--color-white);
    }
  }
`;

export const ExtraText = styled.p`
  font: var(--font-14);
  letter-spacing: 0.25px;
  color: var(--color-black);
  text-transform: uppercase;
  @media (max-width: 690px) {
    margin: 0 15px;
  }
`;
