import React, { useRef, useContext, useState, useMemo, useCallback, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { prop, values } from 'ramda';
import PropTypes from 'prop-types';

import { AppContext } from 'utils/contexts';
import { parseDayAndDate } from 'utils';
import ExpandIcon from 'assets/svg/ExpandIcon';

import Event from './Event';
import { TitleWrap, DayText, TextWrap, Text, IconWrap, Container } from './styles';

const Section = ({ date, events, isFuture }) => {
  const ref = useRef();
  const [{ filters, indexValue }] = useContext(AppContext);
  // const isPrevious = useMemo(() => {
  //   const d = new Date();

  //   return new Date(date) < new Date(d.getFullYear(), d.getMonth(), d.getDate());
  // }, [date]);
  const isViewed = useMemo(() => events.map(prop('index')).includes(indexValue.get()), [events, indexValue]);
  const hasFilters = useMemo(() => Boolean(filters && values(filters).filter(Boolean)?.length), [filters]);
  const defaultOpen = useMemo(() => hasFilters || isFuture || isViewed, [isFuture, hasFilters, isViewed]);
  const [isOpenEvents, setIsOpenEvents] = useState(defaultOpen);
  const { i18n } = useTranslation();

  const [day, parsedDate] = useMemo(() => parseDayAndDate(date, i18n.language), [date, i18n.language]);
  const handleOpen = useCallback(() => setIsOpenEvents(($) => !$), []);

  useEffect(() => {
    if (ref.current && isViewed) {
      try {
        ref.current.scrollIntoView({ behavior: 'smooth', block: 'start' });
      } catch (e) {
        console.error(e); // eslint-disable-line no-console
      }
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <>
      <TitleWrap ref={ref} $isGrey={!isFuture} onClick={handleOpen}>
        <DayText $isGrey={!isFuture}>{day}</DayText>
        <TextWrap $isGrey={!isFuture}>
          <Text $isBlack={!isFuture}>{parsedDate}</Text>
          <IconWrap $isBlack={!isFuture} $isOpen={isOpenEvents}>
            <ExpandIcon />
          </IconWrap>
        </TextWrap>
      </TitleWrap>
      <Container $isOpen={isOpenEvents}>
        {events.map(({ id, index, timeFrom, timeTo, title, type, location }) => (
          <Event
            key={id}
            id={id}
            index={index}
            indexValue={indexValue}
            timeFrom={timeFrom}
            timeTo={timeTo}
            title={title}
            type={type}
            location={location}
            isPrevious={!isFuture}
          />
        ))}
      </Container>
    </>
  );
};

Section.defaultProps = { isFuture: false };
Section.propTypes = {
  date: PropTypes.string.isRequired,
  events: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      timeFrom: PropTypes.string.isRequired,
      timeTo: PropTypes.string.isRequired,
      title: PropTypes.string.isRequired,
      type: PropTypes.arrayOf(PropTypes.string.isRequired),
      location: PropTypes.arrayOf(PropTypes.string.isRequired),
      organizer: PropTypes.string.isRequired,
    }).isRequired
  ).isRequired,
  isFuture: PropTypes.bool,
};

export default Section;
