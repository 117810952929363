import React, { useRef, useContext, useState, useCallback, useLayoutEffect } from 'react';
import PropTypes from 'prop-types';

import { AppContext } from 'utils/contexts';

import Carousel from './Carousel';
import AnimatedCard from './AnimatedCard';
import { Container } from './styles';

const Media = ({ index, video, image, title, isHiddenTitle, titleColor }) => {
  const config = useRef({
    width: window.innerWidth,
    height: window.innerHeight,
  });
  const [{ indexValue }] = useContext(AppContext);
  const [key, setKey] = useState(0);

  const handleResize = useCallback(() => {
    const { width, height } = config.current;

    if (
      (window.innerWidth > window.innerHeight && width <= height) ||
      (window.innerWidth <= window.innerHeight && width > height) ||
      (width >= 700 && window.innerWidth < 700) ||
      (width < 700 && window.innerWidth >= 700) ||
      (height >= 700 && window.innerHeight < 700) ||
      (height < 700 && window.innerHeight >= 700)
    ) {
      setKey(($) => $ + 1);
      config.current = {
        ...config.current,
        width: window.innerWidth,
        height: window.innerHeight,
      };
    }
  }, []);

  useLayoutEffect(() => {
    window.addEventListener('resize', handleResize);

    return () => window.removeEventListener('resize', handleResize);
  }, [handleResize]);

  useLayoutEffect(() => {
    if (typeof index === 'number' && index !== indexValue.get()) {
      indexValue.set(index);
      setKey(($) => $ + 1);
    }
  }, [index, indexValue]);

  return (
    <>
      <Container>
        <Carousel key={key} />
      </Container>
      <AnimatedCard video={video} image={image} title={title} isHiddenTitle={isHiddenTitle} titleColor={titleColor} />
    </>
  );
};

Media.defaultProps = { video: null, image: null };
Media.propTypes = {
  index: PropTypes.number.isRequired,
  video: PropTypes.string,
  image: PropTypes.string,
  title: PropTypes.string.isRequired,
  isHiddenTitle: PropTypes.bool.isRequired,
  titleColor: PropTypes.string.isRequired,
};

export default Media;
