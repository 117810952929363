import React, { useContext, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

import { AppContext } from 'utils/contexts';
import { parseDayAndDate } from 'utils';
import CloseIcon from 'assets/svg/CloseIcon';
import Language from '../Language';

import { Container, Button, Wrapper, Text, Pill } from './styles';

const EventHeader = ({ id }) => {
  const { i18n } = useTranslation();
  const [{ eventsObject }] = useContext(AppContext);
  const event = eventsObject[id];
  const { date, timeFrom, timeTo, type, location } = event || {};
  const [formattedDay, formattedDate] = useMemo(() => parseDayAndDate(date, i18n.language), [date, i18n.language]);

  if (!event) return null;

  return (
    <Container>
      <Button to="/calendar" aria-label="Calendar Page">
        <CloseIcon />
      </Button>
      <Wrapper>
        <Text $isBlack>{formattedDay}</Text>
        <Text $isBlack>{formattedDate}</Text>
        <Text>{`${timeFrom}–${timeTo} H`}</Text>
        <Pill items={type} />
        <Pill items={location} />
      </Wrapper>
      <Language />
    </Container>
  );
};

EventHeader.propTypes = { id: PropTypes.string.isRequired };

export default EventHeader;
