import styled from 'styled-components';
import { motion } from 'framer-motion';

export const Container = styled(motion.div)`
  flex: 1 1 auto;
  display: flex;
  flex-direction: column;
  width: 100%;
  touch-action: none;
  user-select: none;
  min-height: 400px;
  @media (orientation: portrait) {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 0;
  }
`;

export const Wrapper = styled.div`
  flex: 1 1 auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  perspective: 500px;
  overflow: hidden;
  padding: 0 30px 30px;
  width: 100%;
  @media (orientation: portrait) {
    padding: 0;
  }
`;

export const Figure = styled(motion.figure)`
  flex: 0 0 auto;
  width: 530px;
  height: 530px;
  transform-style: preserve-3d;
  transition: transform 0.5s;
  transform-origin: 50% 50%;
  @media (max-width: 699px), (max-height: 699px) {
    width: 350px;
    height: 350px;
  }
`;

export const CardWrap = styled.div`
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
  transform-origin: 50% 50%;
  backface-visibility: hidden;
  @media (orientation: landscape) {
    transform: ${({ $index, $length }) =>
      `rotateY(${($index * 2 * Math.PI) / $length}rad) translateZ(${670 / (2 * Math.tan(Math.PI / $length))}px)`};
  }
  @media (orientation: landscape) and (max-width: 699px), (max-height: 699px) {
    transform: ${({ $index, $length }) =>
      `rotateY(${($index * 2 * Math.PI) / $length}rad) translateZ(${390 / (2 * Math.tan(Math.PI / $length))}px)`};
  }
  @media (orientation: portrait) {
    transform: ${({ $index, $length }) =>
      `rotateX(${(-$index * 2 * Math.PI) / $length}rad) translateZ(${670 / (2 * Math.tan(Math.PI / $length))}px)`};
  }
  @media (orientation: portrait) and (max-width: 699px) {
    transform: ${({ $index, $length }) =>
      `rotateX(${(-$index * 2 * Math.PI) / $length}rad) translateZ(${390 / (2 * Math.tan(Math.PI / $length))}px)`};
  }
`;
