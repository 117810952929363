import React, { useContext, useRef, useState, useCallback, useMemo, useLayoutEffect } from 'react';
import { pipe, values, filter, join } from 'ramda';

import { AppContext } from 'utils/contexts';
import PageTitle from 'components/PageTitle';

import Carousel from './Carousel';
import List from './List';
import { Container } from './styles';

const Calendar = () => {
  const [{ showList, filters, isUpdated }] = useContext(AppContext);
  const config = useRef({
    width: window.innerWidth,
    height: window.innerHeight,
    filtersString: pipe(values, filter(Boolean), join(''))(filters || {}),
  });

  const [key, setKey] = useState(0);

  const handleResize = useCallback(() => {
    const { width, height } = config.current;

    if (
      (window.innerWidth > window.innerHeight && width <= height) ||
      (window.innerWidth <= window.innerHeight && width > height) ||
      (width >= 700 && window.innerWidth < 700) ||
      (width < 700 && window.innerWidth >= 700) ||
      (height >= 700 && window.innerHeight < 700) ||
      (height < 700 && window.innerHeight >= 700)
    ) {
      setKey(($) => $ + 1);
      config.current = {
        ...config.current,
        width: window.innerWidth,
        height: window.innerHeight,
      };
    }
  }, []);

  const filtersString = useMemo(() => pipe(values, filter(Boolean), join(''))(filters || {}), [filters]);

  useLayoutEffect(() => {
    if (config.current.filtersString !== filtersString) {
      setKey(($) => $ + 1);
      config.current = { ...config.current, filtersString };
    }
  }, [filtersString]);

  useLayoutEffect(() => {
    window.addEventListener('resize', handleResize);

    return () => window.removeEventListener('resize', handleResize);
  }, [handleResize]);

  return (
    <PageTitle>
      <Container key={isUpdated}>{showList ? <List key={key} /> : <Carousel key={key} />}</Container>
    </PageTitle>
  );
};

export default Calendar;
