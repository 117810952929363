import styled from 'styled-components';

import AnimatedPill from 'components/AnimatedPill';

export const Container = styled.button`
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 15px;
  background-color: var(--color-grey-ef);
  position: relative;
  overflow: hidden;
  ${({ $imageUrl }) =>
    $imageUrl &&
    `
      background-image: url(${$imageUrl});
      background-repeat: no-repeat;
      background-size: cover;
      background-position: initial;
  `}
`;

export const Wrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(3, auto) 1fr;
  align-items: center;
  gap: 2px;
  position: absolute;
  top: 2px;
  left: 2px;
  right: 2px;
  min-height: 48px;
  color: ${({ $color }) => $color};
`;

export const Text = styled.p`
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100%;
  min-width: 48px;
  background-color: rgba(255, 255, 255, 0.75);
  font: var(--font-14);
  letter-spacing: 0.25px;
  color: inherit;
  text-align: center;
  text-transform: uppercase;
  padding: 0 15px;
  z-index: 1;
  -webkit-transform: translate3d(0, 0, 0);
  &:first-of-type {
    padding: 0;
  }
  @media (orientation: landscape) and (max-width: 699px), (max-height: 699px) {
    padding: 0 7px;
  }
  @media (orientation: portrait) and (max-width: 699px) {
    padding: 0 7px;
  }
`;

export const Pill = styled(AnimatedPill)`
  min-height: 100%;
  min-width: 48px;
  background-color: rgba(255, 255, 255, 0.75);
  padding: 1px 17px 0;
  z-index: 1;
  -webkit-transform: translate3d(0, 0, 0);
  @media (orientation: landscape) and (max-width: 699px), (max-height: 699px) {
    padding: 0 7px;
  }
  @media (orientation: portrait) and (max-width: 699px) {
    padding: 0 7px;
  }
`;

export const Title = styled.h2`
  font: var(--font-32);
  letter-spacing: -0.5px;
  color: ${({ $color }) => $color};
  text-align: center;
  text-transform: uppercase;
  margin: 0 60px;
  z-index: 1;
  -webkit-transform: translate3d(0, 0, 0);
  @media (orientation: landscape) and (max-width: 699px), (max-height: 699px) {
    font: var(--font-22);
    letter-spacing: -0.4px;
    margin: 0 35px;
  }
  @media (orientation: portrait) and (max-width: 699px) {
    font: var(--font-22);
    letter-spacing: -0.4px;
    margin: 0 35px;
  }
`;

export const VideoWrap = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 0;
  height: 100%;
  min-height: 100%;
  width: auto;
  min-width: 100%;
  background-color: var(--color-grey-ef);
  pointer-events: none;
  z-index: 0;
  & > div {
    height: 100%;
    aspect-ratio: 16/9;
  }
  & iframe {
    height: 100%;
    aspect-ratio: 16/9;
  }
`;
