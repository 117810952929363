import { pipe, times, map, includes } from 'ramda';

export const checkVisible = (currentIndex, index, length) => {
  const circles = Math.floor(currentIndex / length);
  const restIndex = currentIndex - circles * length;

  if (length < 2) return [true, true];
  if (length === 2) return [restIndex === index, restIndex === index];

  return [
    pipe(
      times(($) => -4 + $),
      map((v) => {
        const diff = restIndex + v;

        if (diff < 0) return length + diff;
        if (diff > length - 1) return diff - length - 1;

        return diff;
      }),
      includes(index)
    )(9),
    restIndex === index,
  ];
};

export const checkMouseMove = (x1, x2, y1, y2) => {
  if (!(x1 && x2 && y1 && y2)) return true;

  return Math.sqrt((x1 - x2) ** 2 + (y1 - y2) ** 2) > 20;
};
