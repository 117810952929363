import React, { useContext, useMemo } from 'react';
import { useTransform } from 'framer-motion';

import { AppContext } from 'utils/contexts';

import Card from './Card';
import { Container, Wrapper, Figure, CardWrap } from './styles';

const Carousel = () => {
  const [{ filteredPastEvents, filteredFutureEvents, indexValue }] = useContext(AppContext);
  const items = useMemo(() => [...filteredFutureEvents, ...filteredPastEvents], [filteredPastEvents, filteredFutureEvents]);
  const rotateY = useTransform(indexValue, ($) => `${$ * ((-2 * Math.PI) / items.length) + Math.PI}rad`);
  const translateZ = useTransform(indexValue, () => `${-670 / (2 * Math.tan(Math.PI / Math.max(2, items.length)))}px`);

  return (
    <Container>
      <Wrapper>
        <Figure style={{ rotateY, translateZ }}>
          {items.map(({ id, index, title, isHiddenTitle, titleColor, image, video }) => (
            <CardWrap key={id} $index={index} $length={Math.max(2, items.length)}>
              <Card
                indexValue={indexValue}
                index={index}
                length={items.length}
                title={title}
                isHiddenTitle={isHiddenTitle}
                titleColor={titleColor}
                video={video}
                image={image}
              />
            </CardWrap>
          ))}
        </Figure>
      </Wrapper>
    </Container>
  );
};

export default Carousel;
