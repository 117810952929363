import { useContext, useMemo } from 'react';
import { values } from 'ramda';

import { AppContext } from 'utils/contexts';

const getEventsObject = (list = [], start = 0) =>
  list.reduce((acc, item, index) => {
    const key = item.date.split('T')[0];
    const date = new Date(item.date);
    const nextItemYear = list[index + 1]?.date && new Date(list[index + 1].date).getFullYear();
    const yearLabel = nextItemYear && nextItemYear !== date.getFullYear() ? nextItemYear : null;

    if (!acc[key]) acc[key] = { id: key, date: item.date, events: [] };
    if (yearLabel) acc[key].yearLabel = yearLabel;

    acc[key].events.push(item);

    return acc;
  }, {});

export const useSeparatedList = () => {
  const [{ filteredPastEvents, filteredFutureEvents }] = useContext(AppContext);

  return useMemo(() => {
    const next = values(getEventsObject(filteredFutureEvents, 0));

    return [next, values(getEventsObject(filteredPastEvents, next.length))];
  }, [filteredFutureEvents, filteredPastEvents]);
};
