import React from 'react';
import { useTranslation } from 'react-i18next';

import { useImprintPageData } from './hooks';
import { Container, Title, Wrapper, MarkdownText, ErrMessage } from './styles';

const Imprint = () => {
  const { t } = useTranslation();
  const { loading, error, participants, groups, coordination, design } = useImprintPageData();

  if (loading) return null;
  if (error) return <ErrMessage>{error}</ErrMessage>;

  return (
    <Container>
      <Wrapper>
        <Title>{t('Participants')}</Title>
        <MarkdownText $isLarge dangerouslySetInnerHTML={{ __html: participants }} />
      </Wrapper>
      <Wrapper>
        <Title>{t('Groups')}</Title>
        <MarkdownText dangerouslySetInnerHTML={{ __html: groups }} />
      </Wrapper>
      <Wrapper>
        <Title>{t('Coordination')}</Title>
        <MarkdownText dangerouslySetInnerHTML={{ __html: coordination }} />
      </Wrapper>
      <Wrapper>
        <Title>{t('Design')}</Title>
        <MarkdownText dangerouslySetInnerHTML={{ __html: design }} />
      </Wrapper>
    </Container>
  );
};

export default Imprint;
