import { gql } from '@apollo/client';

export const GET_DATA = gql`
  query GetData {
    categories(first: 100) {
      nodes {
        id
        name
        slug
        parentId
        events(first: 200) {
          nodes {
            id
          }
        }
      }
    }
    page(id: "cG9zdDoyMQ==") {
      days {
        days {
          posts {
            ... on Page_Days_days_Posts_EventLayout {
              events {
                ... on Event {
                  id
                  event_content {
                    title
                    hiddenTitle
                    titleColor
                    dateNew
                    timeFrom
                    timeTo
                    organizer
                    description
                    descriptionEn
                    extraText
                    extraTextEn
                    program {
                      timeFrom
                      timeTo
                      name
                    }
                    lineUp {
                      name
                      nameEn
                      items {
                        timeFrom
                        timeTo
                        text
                        link {
                          url
                          title
                          target
                        }
                      }
                    }
                    links {
                      link {
                        url
                        title
                        target
                      }
                    }
                    image {
                      mediaItemUrl
                    }
                    video
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;

export const GET_ABOUT_PAGE_DATA = gql`
  query WpAboutQuery {
    page(id: "cG9zdDo4Mzc=") {
      about {
        openCall
        openCallEn
        general
        generalEn
        manifest
        manifestEn
        contact
        contactEn
        links {
          link {
            url
            title
            target
          }
        }
      }
    }
  }
`;

export const GET_SPACE_PAGE_DATA = gql`
  query WpSpaceQuery {
    page(id: "cG9zdDo2Nw==") {
      space {
        description
        descriptionEn
        uploadTextBefore
        uploadTextBeforeEn
        uploadTextAfter
        uploadTextAfterEn
        thanksMessage
        thanksMessageEn
      }
    }
  }
`;

export const GET_IMPRINT_PAGE_DATA = gql`
  query WpImprintQuery {
    page(id: "cG9zdDo5ODQ=") {
      imprint {
        participants
        groups
        coordination
        design
      }
    }
  }
`;
