import React, { useCallback } from 'react';
import PropTypes from 'prop-types';

import ArrowIcon from 'assets/svg/ArrowIcon';
import AddIcon from 'assets/svg/AddIcon';

import { Container, CursorBox, Wrapper, Text, Pill } from './styles';

const Event = ({ id, index, indexValue, timeFrom, timeTo, title, type, location, isPrevious }) => {
  const handleClick = useCallback(() => indexValue.set(index), [index, indexValue]);

  return (
    <Container to={`/event/${id}`} onClick={handleClick} $isPrevious={isPrevious}>
      <CursorBox $isActive={indexValue.get() === index} $isPrevious={isPrevious}>
        <ArrowIcon />
      </CursorBox>
      <Wrapper $isActive={indexValue.get() === index}>
        <Text>{`${timeFrom}–${timeTo} H`}</Text>
        <Text>{title}</Text>
        <Pill items={type} />
        <Pill items={location} />
        <AddIcon />
      </Wrapper>
    </Container>
  );
};

Event.defaultProps = {
  type: [],
  location: [],
};
Event.propTypes = {
  id: PropTypes.string.isRequired,
  index: PropTypes.number.isRequired,
  indexValue: PropTypes.shape({
    set: PropTypes.func.isRequired,
    get: PropTypes.func.isRequired,
  }).isRequired,
  timeFrom: PropTypes.string.isRequired,
  timeTo: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  type: PropTypes.arrayOf(PropTypes.string.isRequired),
  location: PropTypes.arrayOf(PropTypes.string.isRequired),
  isPrevious: PropTypes.bool.isRequired,
};

export default Event;
