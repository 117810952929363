import styled from 'styled-components';

export const Button = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  width: fit-content;
  height: 42px;
  border: 1px solid var(--color-black);
  border-radius: 21px;
  font: var(--font-14);
  color: var(--color-black);
  letter-spacing: 0.25px;
  text-transform: uppercase;
  padding: 1px 17px 0;
  @media (hover) {
    &:hover {
      background-color: var(--color-black);
      color: var(--color-white);
    }
  }
`;
