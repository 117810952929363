import React from 'react';
import PropTypes from 'prop-types';

import { Container } from './styles';

const Snap2 = ({ htmlText }) => <Container dangerouslySetInnerHTML={{ __html: htmlText }} />;

Snap2.defaultProps = { htmlText: '' };
Snap2.propTypes = { htmlText: PropTypes.string };

export default Snap2;
