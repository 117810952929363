import { useRef, useState, useEffect, useCallback } from 'react';

const useAsyncState = (init) => {
  const isMounted = useRef(true);
  const [state, setState] = useState(init);

  const updateState = useCallback((data) => {
    if (isMounted.current) {
      setState(data);
    }
  }, []);

  useEffect(
    () => () => {
      isMounted.current = false;
    },
    []
  );

  return [state, updateState, isMounted];
};

export default useAsyncState;
