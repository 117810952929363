import React, { useRef, useState, useMemo, useCallback, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import Player from 'react-player';
import PropTypes from 'prop-types';

import { PLAYER_CONFIG } from 'utils/consts';
import { parseDayAndDate } from 'utils';

import { checkVisible, checkMouseMove } from './utils';
import { Container, Wrapper, Text, Pill, Title, VideoWrap } from './styles';

const Card = ({
  indexValue,
  id,
  index,
  length,
  video,
  image,
  date,
  type,
  title,
  isHiddenTitle,
  titleColor,
  timeFrom,
  timeTo,
}) => {
  const coordRef = useRef({});
  const { i18n } = useTranslation();
  const navigate = useNavigate();
  const [[visible, playing], setState] = useState(checkVisible(indexValue.get(), index, length));
  const [formattedDay, formattedDate] = useMemo(
    () => (visible ? parseDayAndDate(date, i18n.language) : ['', '']),
    [date, i18n.language, visible]
  );
  const onMouseDown = useCallback((e) => {
    coordRef.current = { x: e.clientX, y: e.clientY };
  }, []);
  const onMouseUp = useCallback(
    (e) => {
      const { clientX, clientY, currentTarget } = e;

      if (checkMouseMove(coordRef.current.x, clientX, coordRef.current.y, clientY)) {
        coordRef.current = {};

        return;
      }

      const { index: eventIndex, id: eventId } = currentTarget.dataset;
      const current = indexValue.get();
      const circles = Math.floor(current / length);
      const restIndex = current - circles * length;

      if (Number(eventIndex) === restIndex && eventId) {
        navigate(`/event/${eventId}`);

        return;
      }

      if (eventIndex) {
        if (Number(eventIndex) > restIndex + 3) {
          indexValue.set(current - (length - Number(eventIndex) + restIndex));
        } else if (Number(eventIndex) < restIndex - 3) {
          indexValue.set(current + (length - restIndex + Number(eventIndex)));
        } else {
          indexValue.set(current + Number(eventIndex) - restIndex);
        }
      }
    },
    [indexValue, length, navigate]
  );
  const handleChangeIndex = useCallback(
    ($) => {
      setState(checkVisible($, index, length));
    },
    [index, length]
  );

  useEffect(() => indexValue.onChange(handleChangeIndex), [indexValue, handleChangeIndex]);

  if (!visible) return null;

  return (
    <Container
      type="button"
      data-id={id}
      data-index={index}
      $imageUrl={video ? null : image}
      onMouseDown={onMouseDown}
      onMouseUp={onMouseUp}
    >
      {video && (
        <Player
          url={video}
          playing={playing}
          volume={0}
          wrapper={VideoWrap}
          width="auto"
          height="100%"
          playIcon={null}
          poster={null}
          config={PLAYER_CONFIG}
          playsinline
          loop
          muted
        />
      )}
      <Wrapper $color={titleColor}>
        <Text>{formattedDay}</Text>
        <Text>{formattedDate}</Text>
        <Text>{`${timeFrom}–${timeTo} H`}</Text>
        <Pill items={type} color={titleColor} />
      </Wrapper>
      {!isHiddenTitle && <Title $color={titleColor}>{title}</Title>}
    </Container>
  );
};

Card.defaultProps = {
  type: [],
  video: null,
  image: null,
};
Card.propTypes = {
  indexValue: PropTypes.shape({
    get: PropTypes.func.isRequired,
    set: PropTypes.func.isRequired,
    onChange: PropTypes.func.isRequired,
  }).isRequired,
  id: PropTypes.string.isRequired,
  index: PropTypes.number.isRequired,
  length: PropTypes.number.isRequired,
  video: PropTypes.string,
  image: PropTypes.string,
  date: PropTypes.string.isRequired,
  type: PropTypes.arrayOf(PropTypes.string.isRequired),
  title: PropTypes.string.isRequired,
  isHiddenTitle: PropTypes.bool.isRequired,
  titleColor: PropTypes.string.isRequired,
  timeFrom: PropTypes.string.isRequired,
  timeTo: PropTypes.string.isRequired,
};

export default Card;
