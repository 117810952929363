import React from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

import { Container, Wrapper, Title, Text, Box } from './styles';

const Snap5 = ({ htmlText1, htmlText2 }) => {
  const { t } = useTranslation();

  return (
    <Container>
      <Wrapper>
        <Title>{t('Manifest')}</Title>
        <Text dangerouslySetInnerHTML={{ __html: htmlText1 }} />
        <Box dangerouslySetInnerHTML={{ __html: htmlText2 }} />
      </Wrapper>
    </Container>
  );
};

Snap5.defaultProps = { htmlText1: '', htmlText2: '' };
Snap5.propTypes = { htmlText1: PropTypes.string, htmlText2: PropTypes.string };

export default Snap5;
