import styled from 'styled-components';

export const Container = styled.div`
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: var(--color-black);
  z-index: 1000;
  -webkit-transform: translate3d(0, 0, 0.1px);
`;

export const Button = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: var(--color-grey-ef);
  width: 49px;
  height: 49px;
  @media (hover) {
    &:hover > svg {
      fill: var(--color-grey-a7);
    }
  }
`;

export const Text = styled.p`
  font: var(--font-19);
  letter-spacing: 0px;
  color: var(--color-white);
  text-transform: uppercase;
  padding: 15px;
`;
