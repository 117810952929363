import styled from 'styled-components';
import { Link } from 'react-router-dom';

import AnimatedPill from 'components/AnimatedPill';

export const Container = styled.header`
  display: grid;
  grid-template-columns: 49px 1fr 48px;
  grid-template-rows: 49px;
  align-items: center;
  column-gap: 2px;
  width: 100%;
  height: 49px;
  z-index: 100;
  position: fixed;
  top: 0;
`;

export const Button = styled(Link)`
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: var(--color-grey-ef);
  width: 100%;
  height: 100%;
  @media (hover) {
    &:hover > svg {
      fill: var(--color-grey-a7);
    }
  }
`;

export const Wrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(5, auto);
  grid-template-rows: 49px;
  column-gap: 2px;
  justify-content: start;
  align-items: center;
  @media (max-width: 991px) {
    grid-template-columns: repeat(2, auto) 1fr;
  }
`;

export const Text = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  min-width: 49px;
  background-color: ${({ $isBlack }) => ($isBlack ? 'var(--color-black)' : 'var(--color-white)')};
  font: var(--font-14);
  letter-spacing: 0.25px;
  color: ${({ $isBlack }) => ($isBlack ? 'var(--color-white)' : 'var(--color-black)')};
  text-transform: uppercase;
  padding: 0 15px;
  &:first-of-type {
    padding: 0;
  }
  @media (max-width: 991px) {
    align-self: center;
  }
`;

export const Pill = styled(AnimatedPill)`
  height: 42px;
  width: auto;
  border-radius: 21px;
  margin-right: 8px;
  @media (max-width: 991px) {
    display: none;
  }
`;
