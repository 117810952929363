import styled from 'styled-components';

export const Container = styled.div`
  flex: 1 1 auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: absolute;
  top: 25px;
  left: -150%;
  bottom: 0;
  min-width: 400%;
  height: 100%;
  max-height: 100%;
  z-index: -1;
  @media (orientation: portrait) and (max-width: 699px) {
    top: 0;
  }
  @media (orientation: portrait) {
    display: none;
  }
`;
