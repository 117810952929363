import {
  pipe,
  pathOr,
  map,
  propOr,
  filter,
  applySpec,
  prop,
  sort,
  not,
  reduce,
  pick,
  values,
  keys,
  join,
  any,
  includes,
  __,
  find,
  propEq,
  props,
  reverse,
  flatten,
} from 'ramda';

import { DEFAULT_LANGUAGE, DE, EN } from 'locales';

export const getRandomId = (name = 'none_name') => `${name.toLowerCase()}_${String(Math.random()).slice(2)}`;

const getFormatDay = (lng) =>
  new Intl.DateTimeFormat(lng || DEFAULT_LANGUAGE, {
    weekday: 'short',
  });

const formatDate = new Intl.DateTimeFormat(DE, {
  year: '2-digit',
  month: '2-digit',
  day: '2-digit',
});

export const parseDate = (date, lng) =>
  date ? `${getFormatDay(lng).format(new Date(date))}. ${formatDate.format(new Date(date))}` : '';

export const parseDayAndDate = (date, lng) =>
  date ? [getFormatDay(lng).format(new Date(date)), formatDate.format(new Date(date))] : ['', ''];

const getIsoDate = (date) => {
  const d = Date.parse(date);

  return d ? new Date(d) : new Date(null);
};

const getIsoDateForSort = (dateAndTime) => pipe(filter(Boolean), join('T'), getIsoDate)(dateAndTime || []);

const extractEvents = (data) => {
  const days = pathOr([], ['days', 'days'], data || {});

  return days.reduce((acc, day) => {
    if (day?.posts) {
      day.posts.forEach((post) => {
        if (post?.events) {
          post.events.forEach((event) => {
            if (event) acc.push(event);
          });
        }
      });
    }

    return acc;
  }, []);
};

const prepareEvents = (events, categories) => {
  const date = new Date();
  const today = new Date(date.getFullYear(), date.getMonth(), date.getDate());

  return pipe(
    map(
      applySpec({
        id: prop('id'),
        type: ($) => pathOr([], ['type', $.id], categories).map(({ name }) => name),
        location: ($) => pathOr([], ['location', $.id], categories).map(({ name }) => name),
        filters: ($) =>
          [...pathOr([], ['type', $.id], categories), ...pathOr([], ['location', $.id], categories)].map(({ id }) => id),
        title: pathOr('N/A', ['event_content', 'title']),
        isHiddenTitle: pipe(pathOr(false, ['event_content', 'hiddenTitle']), Boolean),
        titleColor: pathOr('#000', ['event_content', 'titleColor']),
        date: pipe(pathOr(null, ['event_content', 'dateNew']), getIsoDate),
        dateForSort: pipe(propOr({}, 'event_content'), props(['dateNew', 'timeFrom']), getIsoDateForSort),
        timeFrom: pathOr('N/A', ['event_content', 'timeFrom']),
        timeTo: pathOr('N/A', ['event_content', 'timeTo']),
        organizer: pathOr('', ['event_content', 'organizer']),
        description: applySpec({
          [DE]: pathOr('', ['event_content', 'description']),
          [EN]: pathOr('', ['event_content', 'descriptionEn']),
        }),
        extraText: applySpec({
          [DE]: pathOr('', ['event_content', 'extraText']),
          [EN]: pathOr('', ['event_content', 'extraTextEn']),
        }),
        program: pathOr(null, ['event_content', 'program']),
        lineUp: pathOr(null, ['event_content', 'lineUp']),
        links: pathOr([], ['event_content', 'links']),
        image: pathOr(null, ['event_content', 'image', 'mediaItemUrl']),
        video: pathOr(null, ['event_content', 'video']),
      })
    ),
    sort((a, b) => a.dateForSort - b.dateForSort),
    reduce(
      (acc, item) => {
        const year = item.date?.getFullYear();

        if (item.date < today && year && !acc.pastEvents[year]) acc.pastEvents[year] = [];
        if (item.date < today && year) acc.pastEvents[year].push({ ...item, date: item.date.toISOString() });
        if (item.date >= today) acc.futureEvents.push({ ...item, date: item.date.toISOString() });

        return acc;
      },
      { pastEvents: {}, futureEvents: [] }
    ),
    applySpec({
      futureEvents: prop('futureEvents'),
      pastEvents: pipe(prop('pastEvents'), values, reverse, flatten),
    })
  )(events || []);
};

const extractCategories = (categories) => {
  const items = propOr([], ['nodes'], categories || {});

  if (!items?.length) return [{}, {}];

  const parents = pipe(
    filter(pipe(prop('parentId'), not)),
    reduce((acc, parent) => {
      acc[parent.id] = pick(['id', 'name', 'slug'], parent);

      return acc;
    }, {})
  )(items);

  const filterObj = items.reduce((acc, item) => {
    if (acc[item.parentId] && !acc[item.parentId].items) acc[item.parentId].items = [];
    if (acc[item.parentId]) {
      acc[item.parentId].items.push(item);
    }

    return acc;
  }, parents);

  return [
    pipe(values, filter(prop('items')))(filterObj),
    pipe(
      keys,
      filter(($) => filterObj[$].items),
      reduce((acc, key) => {
        acc[key] = null;
        return acc;
      }, {})
    )(filterObj),
    pipe(
      keys,
      filter(($) => filterObj[$].items),
      reduce((acc, key) => {
        const field = (filterObj[key].slug === 'type' && 'type') || (filterObj[key].slug === 'location' && 'location') || null;

        if (field)
          acc[field] = filterObj[key].items.reduce((subAcc, { id, name, events }) => {
            events.nodes.forEach(({ id: eventId }) => {
              if (subAcc[eventId]) {
                subAcc[eventId].push({ id, name });
              } else {
                // eslint-disable-next-line no-param-reassign
                subAcc[eventId] = [{ id, name }];
              }
            });

            return subAcc;
          }, {});

        return acc;
      }, {})
    )(filterObj),
    pipe(find(propEq('slug', 'radio')))(items),
  ];
};

const throughFiler = (list, filterObject) => {
  const filterIds = pipe(values, filter(Boolean))(filterObject || {});

  if (filterIds.length < 1) return list;

  return list.filter(pipe(prop('filters'), any(includes(__, filterIds))));
};

export const applyFilterToList = (futureList, pastList, filterObject) => {
  const filteredFuture = throughFiler(futureList, filterObject);
  const filteredPast = throughFiler(pastList, filterObject);
  let index = 0;

  return {
    filteredFutureEvents: filteredFuture.map((item, i) => {
      if (i !== 0) index += 1;

      return { ...item, index };
    }),
    filteredPastEvents: filteredPast.map((item, i) => {
      if (filteredFuture.length || i !== 0) index += 1;

      return { ...item, index };
    }),
  };
};

export const parseServerData = (data, categories) => {
  const [filtersMenu, filters, categoryObj, category] = extractCategories(categories);
  const { pastEvents, futureEvents } = prepareEvents(extractEvents(data), categoryObj);

  return {
    eventsObject: [...futureEvents, ...pastEvents].reduce((acc, item) => {
      acc[item.id] = item;

      return acc;
    }, {}),
    pastEvents,
    futureEvents,
    ...applyFilterToList(futureEvents, pastEvents, filters),
    filtersMenu,
    filters,
    radio: { category },
  };
};
