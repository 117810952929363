import React, { useContext, useState, useMemo, useCallback, useEffect, Fragment } from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

import { AppContext } from 'utils/contexts';
import { getRandomId, applyFilterToList } from 'utils';

import CloseIcon from './CloseIcon';
import { Container, Wrapper, Items, Button, ItemButton, ContentWrap, Span, IconWrap, BlackButton } from './styles';

const FilterPanel = ({ isFromHeader, className }) => {
  const { t } = useTranslation();
  const [{ filters, showList, filtersMenu, indexValue }, setContext] = useContext(AppContext);
  const [openedTypes, setOpenedTypes] = useState(null);

  const containerId = useMemo(() => getRandomId('filter_panel_container'), []);

  const handleOpen = useCallback(({ currentTarget }) => {
    const { id: typeId } = currentTarget.dataset;

    if (typeId) setOpenedTypes(($) => ($ && $ === typeId ? null : typeId));
  }, []);
  const handleSelect = useCallback(
    ({ currentTarget }) => {
      const { type, id: itemId } = currentTarget.dataset;

      if (!(type && itemId)) return;

      setContext(($) => {
        const newFilters = { ...$.filters, [type]: $.filters[type] === itemId ? null : itemId };

        return {
          ...$,
          filters: newFilters,
          ...applyFilterToList($.futureEvents, $.pastEvents, newFilters),
        };
      });
      indexValue.set(0);
    },
    [setContext, indexValue]
  );
  const handleBodyClick = useCallback(
    ({ target }) => {
      if (!target.closest(`#${containerId}`)) setOpenedTypes();
    },
    [containerId]
  );

  const onSwitch = useCallback(() => setContext(($) => ({ ...$, showList: !$.showList })), [setContext]);

  useEffect(() => {
    if (!openedTypes) return () => null;

    window.document.addEventListener('click', handleBodyClick);

    return () => window.document.removeEventListener('click', handleBodyClick);
  }, [openedTypes, handleBodyClick]);

  return (
    <Container $isFromHeader={isFromHeader} className={className}>
      <Wrapper id={containerId} $length={filtersMenu.length * 2}>
        {filtersMenu.map(({ id, name, items }) => (
          <Fragment key={id}>
            <Button type="button" data-id={id} onClick={handleOpen}>
              {name}
            </Button>
            <Items $length={items.length}>
              {items.map(({ id: itemId, name: itemName }) => {
                const isSelected = filters[id] === itemId;

                return (
                  <ItemButton
                    key={itemId}
                    type="button"
                    data-type={id}
                    data-id={itemId}
                    onClick={handleSelect}
                    $isOpen={openedTypes === id || isSelected}
                    $isSelected={isSelected}
                  >
                    <ContentWrap>
                      <Span>{itemName}</Span>
                      <IconWrap $isSelected={isSelected}>
                        <CloseIcon />
                      </IconWrap>
                    </ContentWrap>
                  </ItemButton>
                );
              })}
            </Items>
          </Fragment>
        ))}
      </Wrapper>
      <BlackButton type="button" onClick={onSwitch}>
        {showList ? t('Tiles') : t('List')}
      </BlackButton>
    </Container>
  );
};

FilterPanel.defaultProps = {
  isFromHeader: false,
  className: '',
};
FilterPanel.propTypes = {
  isFromHeader: PropTypes.bool,
  className: PropTypes.string,
};

export default FilterPanel;
