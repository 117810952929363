export const INPUTS = [
  {
    kind: 'input',
    type: 'email',
    name: 'email',
    sheetId: 'E-Mail',
  },
  {
    kind: 'input',
    type: 'text',
    name: 'name',
    sheetId: 'Name',
  },
  {
    kind: 'input',
    type: 'tel',
    name: 'phone',
    sheetId: 'Phone number',
  },
  {
    kind: 'input',
    type: 'text',
    name: 'eventTitle',
    sheetId: 'Title of the event',
  },
  {
    kind: 'checkboxes',
    name: 'date',
    sheetId: 'Preferred date of your event',
    items: [
      { id: '10.07', label: 'Wed. 10.07' },
      { id: '11.07', label: 'Thur. 11.07' },
      { id: '12.07', label: 'Fri. 12.07' },
      { id: '14.07', label: 'Sun. 14.07' },
      { id: '17.07', label: 'Wed. 17.07' },
      { id: '18.07', label: 'Thur. 18.07' },
      { id: '19.07', label: 'Fri. 19.07' },
      { id: '20.07', label: 'Sat. 20.07' },
      { id: '21.07', label: 'Sun. 21.07' },
      { id: '24.07', label: 'Wed. 24.07' },
      { id: '25.07', label: 'Thur. 25.07' },
      { id: '26.07', label: 'Fri. 26.07' },
      { id: '27.07', label: 'Sat. 27.07' },
      { id: '31.07', label: 'Wed. 31.07' },
    ],
  },
  {
    kind: 'checkboxes',
    name: 'time',
    sheetId: 'Preferred time of your event',
    items: [
      { id: '14-17', label: '14 and 17' },
      { id: '17-20', label: '17 and 20' },
      { id: '20-00', label: '20 and 00' },
    ],
  },
  {
    kind: 'input',
    type: 'number',
    name: 'duration',
    sheetId: 'Duration of your event',
  },
  {
    kind: 'checkboxes',
    name: 'venue',
    sheetId: 'Preferred venue at Rote Fabrik',
    items: [
      { id: 'radioVenue', label: 'Radio (Venue)' },
      { id: 'club', label: 'Clubraum' },
      { id: 'foyer', label: 'Foyer Clubraum' },
      { id: 'courtyard', label: 'Courtyard' },
      { id: 'atelier', label: 'Atelier' },
      { id: 'otherVenue', label: 'other' },
    ],
  },
  {
    kind: 'checkboxes',
    name: 'eventType',
    sheetId: 'Type of your event',
    items: [
      { id: 'art', label: 'Art' },
      { id: 'discourse ', label: 'Discourse' },
      { id: 'workshop ', label: 'Workshop' },
      { id: 'action ', label: 'Action' },
      { id: 'radio', label: 'Radio' },
      { id: 'concert', label: 'Concert' },
      { id: 'otherType', label: 'other' },
    ],
  },
  {
    kind: 'input',
    type: 'text',
    name: 'description',
    sheetId: 'Event description',
  },
  {
    kind: 'input',
    type: 'text',
    name: 'additionalInfo',
    sheetId: 'Additional Information',
  },
  {
    kind: 'input',
    type: 'text',
    name: 'artists',
    sheetId: 'Involved artists, guests, performers',
  },
  {
    kind: 'input',
    type: 'text',
    name: 'instagram',
    sheetId: 'Instagram-Handles',
  },
  {
    kind: 'input',
    type: 'text',
    name: 'timetable ',
    sheetId: 'Timetable',
  },
  {
    kind: 'checkboxes',
    name: 'technical',
    sheetId: 'Technical needs',
    items: [
      { id: 'sound_system', label: '4-points Meyer-Soundsystem' },
      { id: 'dj', label: 'DJ-Setup' },
      { id: 'mic', label: '4 radio microphones' },
      { id: 'stereo', label: 'Stereo-In' },
      { id: 'screen', label: 'Beamer & screen' },
      { id: 'printer', label: 'b/w-printer ' },
      { id: 'light_discourse', label: 'light preset discourse' },
      { id: 'light_clubroom', label: 'light preset club' },
      { id: 'chairs', label: 'stools & chairs ' },
      { id: 'backstage', label: 'Backstage' },
      { id: 'other', label: 'other' },
    ],
  },
  {
    kind: 'input',
    type: 'text',
    name: 'comment',
    sheetId: 'Any other needs or comments',
  },
];
