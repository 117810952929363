import React, { useContext, useRef, useMemo, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useMatch } from 'react-router-dom';
import { useAnimation } from 'framer-motion';

import PropTypes from 'prop-types';

import { AppContext } from 'utils/contexts';
import useAsyncState from 'utils/useAsyncState';

import { Container, Title } from './styles';

const PageTitle = ({ loading, children }) => {
  const { t } = useTranslation();
  const [{ isUpdated }] = useContext(AppContext);
  const [timer, setTimer] = useAsyncState(false);
  const controls = useAnimation();
  const initVisible = useRef(Boolean(!isUpdated || loading));

  const isAbout = useMatch('/');
  const isRadio = useMatch('/radio');
  const isSpace = useMatch('/space');
  const isImprint = useMatch('/imprint');
  const title = useMemo(
    () =>
      (isAbout && t('About')) ||
      (isRadio && t('Radio')) ||
      (isSpace && t('Space')) ||
      (isImprint && t('Imprint')) ||
      t('Calendar'),
    [isAbout, isImprint, isRadio, isSpace, t]
  );

  useEffect(() => {
    if (!initVisible.current) return () => null;

    const timeout = setTimeout(() => setTimer(true), 2000);

    return () => clearTimeout(timeout);
  }, [setTimer]);

  useEffect(() => {
    if (timer && isUpdated && !loading) {
      controls.start({
        opacity: 0,
        display: 'none',
        transition: {
          opacity: { duration: 1 },
          display: { delay: 1 },
        },
      });
    }
  }, [controls, isUpdated, loading, timer]);

  return (
    <>
      {children}
      {initVisible.current && (
        <Container animate={controls}>
          <Title>{title}</Title>
        </Container>
      )}
    </>
  );
};

PageTitle.defaultProps = { loading: false };
PageTitle.propTypes = {
  loading: PropTypes.bool,
  children: PropTypes.node.isRequired,
};

export default PageTitle;
