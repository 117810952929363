import styled from 'styled-components';
import { Link as DefLink } from 'react-router-dom';

export const Container = styled.div`
  flex: 1 1 auto;
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 1fr auto;
  width: 100%;
  overflow: auto;
  margin-top: 1px;
  &::-webkit-scrollbar {
    display: none;
  }
  -ms-overflow-style: none;
  scrollbar-width: none;
`;

export const Wrapper = styled.div`
  flex: 1 1 auto;
  position: relative;
`;

export const Label = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: flex-end;
  width: 100%;
  height: 72px;
  font: var(--font-14);
  letter-spacing: 0.25px;
  color: var(--color-black);
  text-transform: uppercase;
  background-color: var(--color-white);
  padding: 15px;
`;

export const LinkWrap = styled.div`
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  justify-content: start;
  gap: 15px;
  padding: 15px;
`;

const linkStyle = `
  display: flex;
  justify-content: center;
  align-items: center;
  width: fit-content;
  height: 42px;
  border: 1px solid var(--color-black);
  border-radius: 21px;
  font: var(--font-14);
  color: var(--color-black);
  letter-spacing: 0.25px;
  text-decoration: none;
  text-transform: uppercase;
  padding: 1px 17px 0;
  @media (hover) {
    &:hover {
      background-color: var(--color-black);
      color: var(--color-white);
    }
  }
`;

export const Link = styled.a`
  ${linkStyle}
`;

export const InternLink = styled(DefLink)`
  ${linkStyle}
`;
