/* eslint-disable react/no-array-index-key */
import React, { useContext, useTransition, useMemo, useCallback, Fragment } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { filter, pipe, propOr, includes, propEq } from 'ramda';

import { AppContext } from 'utils/contexts';
import { parseDayAndDate, applyFilterToList } from 'utils';
import PageTitle from 'components/PageTitle';

import {
  Container,
  Title,
  SectionName,
  Section,
  SectionItem,
  Text,
  TextLink,
  Warn,
  WarnText,
  LinkWrap,
  Button,
  ExternalLink,
} from './styles';

const Radio = () => {
  const { t, i18n } = useTranslation();
  const [
    {
      futureEvents,
      indexValue,
      radio: { category },
      isUpdated,
    },
    setContext,
  ] = useContext(AppContext);
  const [isPending, startTransition] = useTransition();
  const navigate = useNavigate();

  const { id: categoryId, parentId } = category || {};
  const events = useMemo(() => {
    if (!categoryId) return [];

    const radioEvents = pipe(filter(pipe(propOr([], 'filters'), includes(categoryId))))(futureEvents);
    const date = radioEvents[0]?.date;

    if (!date) return [];

    return filter(propEq('date', date), radioEvents);
  }, [futureEvents, categoryId]);

  const title = useMemo(() => {
    if (!events[0]?.date) return '';
    const d = parseDayAndDate(events[0].date, i18n.language)[1];
    const today = parseDayAndDate(Date.now(), i18n.language)[1];

    if (d === today) return `${t('today')} ${t('Program')}`;

    return `${t('Program')} – ${d}`;
  }, [events, i18n.language, t]);
  const applyFilter = useCallback(() => {
    setContext(($) => {
      const newFilters = { ...$.filters, [parentId]: categoryId };

      return {
        ...$,
        filters: newFilters,
        ...applyFilterToList($.futureEvents, $.pastEvents, newFilters),
      };
    });
    indexValue.set(0);
    startTransition(() => {
      navigate('/calendar');
    });
  }, [categoryId, indexValue, navigate, parentId, setContext]);

  return (
    <PageTitle>
      {isUpdated && Boolean(events.length) && (
        <Container>
          <Title>{title}</Title>
          {events.map(({ id, title: eventTitle, program, lineUp }) => (
            <Fragment key={id}>
              {Boolean(program?.length) && (
                <>
                  <SectionName>{eventTitle}</SectionName>
                  <Section>
                    {program.map(({ timeFrom: prTimeFrom, timeTo: prTimeTo, name: prName }, index) => (
                      <SectionItem key={index}>
                        <Text>{`${prTimeFrom}–${prTimeTo} H`}</Text>
                        <Text>{prName}</Text>
                      </SectionItem>
                    ))}
                  </Section>
                </>
              )}
              {Boolean(lineUp && lineUp?.items?.length) && (
                <>
                  <SectionName>{eventTitle}</SectionName>
                  <Section>
                    {lineUp.items.map(({ timeFrom: eTimeFrom, timeTo: eTimeTo, link: eLink, text: eText }, index) => (
                      <SectionItem key={index}>
                        <Text>{`${eTimeFrom}–${eTimeTo} H`}</Text>
                        {eText && !eLink && <Text>{eText}</Text>}
                        {eLink && (
                          <TextLink href={eLink.url} target="_blank" rel="noopener noreferrer">
                            {eLink.title}
                          </TextLink>
                        )}
                      </SectionItem>
                    ))}
                  </Section>
                </>
              )}
            </Fragment>
          ))}
          <LinkWrap>
            <Button type="button" onClick={applyFilter} disabled={!(categoryId && parentId) || isPending}>
              {t('Full Radio Program')}
            </Button>
            <ExternalLink href="https://soundcloud.com/summer_camp/" target="_blank" rel="noopener noreferrer">
              SoundCloud
            </ExternalLink>
          </LinkWrap>
        </Container>
      )}
      {isUpdated && !events.length && (
        <Warn $isWarn>
          <WarnText>{t('Sorry we are offline')}</WarnText>
          <LinkWrap $isWarn>
            <Button type="button" onClick={applyFilter} disabled={!(categoryId && parentId) || isPending} $isWarn>
              {t('Full Radio Program')}
            </Button>
            <ExternalLink href="https://soundcloud.com/summer_camp/" target="_blank" rel="noopener noreferrer" $isWarn>
              SoundCloud
            </ExternalLink>
          </LinkWrap>
        </Warn>
      )}
    </PageTitle>
  );
};

export default Radio;
