import React from 'react';
import { useTranslation } from 'react-i18next';

import PageTitle from 'components/PageTitle';

import { useAboutPageData } from './hooks';
import Snap1 from './Snap1';
import Snap2 from './Snap2';
// import Snap3 from './Snap3';
import Snap4 from './Snap4';
import Snap5 from './Snap5';
import Snap6 from './Snap6';
import { Container, ErrMessage } from './styles';

const About = () => {
  const { i18n } = useTranslation();
  const { loading, error, /* openCall, */ general, manifest, contact, links } = useAboutPageData(i18n.language);

  if (error) return <ErrMessage>{error}</ErrMessage>;

  return (
    <PageTitle loading={loading}>
      <Container>
        <Snap1 loading={loading} />
        <Snap2 htmlText={general} />
        {/* <Snap3 /> */}
        <Snap4 />
        <Snap5 htmlText1={manifest} htmlText2={contact} />
        <Snap6 links={links} />
      </Container>
    </PageTitle>
  );
};

export default About;
