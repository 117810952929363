import React from 'react';
import { useLocation, useMatch } from 'react-router-dom';
import PropTypes from 'prop-types';

import FilterPanel from 'components/FilterPanel';
import Language from '../Language';

import Menu from './Menu';
import Radio from './Radio';
import { Container } from './styles';

const Header = ({ isHidden }) => {
  const { pathname } = useLocation();
  const isRadioPage = useMatch('/radio');

  return (
    <Container $isHidden={isHidden} $isWhiteBackground={isRadioPage}>
      <Menu pathname={pathname} />
      <Radio />
      {pathname === '/calendar' && <FilterPanel isFromHeader />}
      <div />
      <Language />
    </Container>
  );
};

Header.defaultProps = { isHidden: false };
Header.propTypes = { isHidden: PropTypes.bool };

export default Header;
