import React from 'react';
import { useTranslation } from 'react-i18next';

import { Container, Wrapper, TextWrap, Text, Br, Link } from './styles';

const Snap4 = () => {
  const { t } = useTranslation();

  return (
    <Container>
      <Wrapper>
        <TextWrap>
          <Text>
            {t('Check out')}
            <Br />
            {t('our full program')}
          </Text>
        </TextWrap>
        <Link to="/calendar">{t('Calendar')}</Link>
      </Wrapper>
    </Container>
  );
};

export default Snap4;
