import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import { EN, DE } from 'locales';
import { Button } from './styles';

const Language = () => {
  const {
    i18n: { language, changeLanguage },
  } = useTranslation();

  const handleClick = useCallback(() => {
    language === EN ? changeLanguage(DE) : changeLanguage(EN);
  }, [changeLanguage, language]);

  return (
    <Button type="button" onClick={handleClick} aria-label="Language">
      {(language === EN && 'EN') || (language === DE && 'DE') || ''}
    </Button>
  );
};

export default Language;
