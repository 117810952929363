import { useCallback, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

export const useMainClick = () => {
  const navigate = useNavigate();

  const handleClick = useCallback(
    (e) => {
      if (!e.target.closest('#event_page_scroll_content')) {
        navigate('/calendar');
      }
    },
    [navigate]
  );

  useEffect(() => {
    const main = document.querySelector('main');

    main?.addEventListener('click', handleClick);

    return () => {
      main?.removeEventListener('click', handleClick);
    };
  }, [handleClick]);
};
