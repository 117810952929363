import React from 'react';
import { Routes, Route, Navigate } from 'react-router-dom';

import GlobalStyles from 'theme/GlobalStyles';
import Layout from 'components/Layout';
import Calendar from 'pages/Calendar';
import Event from 'pages/Event';
import About from 'pages/About';
import Radio from 'pages/Radio';
import Space from 'pages/Space';
import Imprint from 'pages/Imprint';

const Root = () => (
  <>
    <GlobalStyles />
    <Layout>
      <Routes>
        <Route path="/" element={<About />} />
        <Route path="/calendar" element={<Calendar />} />
        <Route path="/event/:id" element={<Event />} />
        <Route path="/radio" element={<Radio />} />
        <Route path="/space" element={<Space />} />
        <Route path="/imprint" element={<Imprint />} />
        <Route path="*" element={<Navigate to="/" />} />
      </Routes>
    </Layout>
  </>
);

export default Root;
