import styled from 'styled-components';

export const Container = styled.div`
  flex: 1 1 auto;
  min-height: 100%;
  scroll-snap-type: y mandatory;
  overflow-y: scroll;
  &::-webkit-scrollbar {
    display: none;
  }
  -ms-overflow-style: none;
  scrollbar-width: none;
  @media (max-height: 959px), (max-width: 1009px) {
    scroll-snap-type: none;
  }
`;

export const Section = styled.section`
  height: 100%;
  min-height: 100%;
  max-height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  scroll-snap-align: start;
  scroll-snap-stop: always;
  overflow: hidden;
  @media (max-height: 959px), (max-width: 1009px) {
    height: auto;
    min-height: 0;
    max-height: none;
    overflow: visible;
  }
`;

export const ErrMessage = styled.p`
  font: var(--font-32);
  color: var(--color-red);
  text-align: center;
  margin: 1em;
`;
