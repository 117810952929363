import React from 'react';
import PropTypes from 'prop-types';

import { Container, Wrapper, Link } from './styles';

const Snap6 = ({ links }) => (
  <Container>
    <Wrapper>
      {links.map(({ url, title, target }) => (
        <Link key={url} href={url} target={target} rel="noopener noreferrer">
          {title}
        </Link>
      ))}
    </Wrapper>
  </Container>
);

Snap6.defaultProps = { links: [] };
Snap6.propTypes = {
  links: PropTypes.arrayOf(
    PropTypes.shape({
      url: PropTypes.string.isRequired,
      title: PropTypes.string.isRequired,
      target: PropTypes.string.isRequired,
    }).isRequired
  ),
};

export default Snap6;
