import React from 'react';
import PropTypes from 'prop-types';

import { Label, Input, Span } from './styles';

const Checkbox = ({ name, label, disabled }) => (
  <Label>
    <Input type="checkbox" name={name} disabled={disabled} />
    <Span>{label}</Span>
  </Label>
);

Checkbox.defaultProps = { disabled: false };
Checkbox.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  disabled: PropTypes.bool,
};

export default Checkbox;
