import React, { useRef, useState, useCallback, useEffect } from 'react';
import Player from 'react-player';
import PropTypes from 'prop-types';

import videoLandscape from 'assets/video/openCallForOrganisation_desktop.mp4';
import videoPortrait from 'assets/video/openCallForOrganisation_mobile.mp4';

import { Container, Wrapper } from './styles';

const Snap1 = ({ loading }) => {
  const ref = useRef();
  const [videoUrl, setVideoUrl] = useState(window.innerWidth > window.innerHeight ? videoLandscape : videoPortrait);

  const handleResize = useCallback(
    () => setVideoUrl(window.innerWidth > window.innerHeight ? videoLandscape : videoPortrait),
    []
  );

  const forcePlay = useCallback(async () => {
    try {
      await ref.current?.player?.player?.player?.play();
    } catch (e) {
      console.error(e); // eslint-disable-line no-console
    }
  }, []);

  useEffect(() => {
    window.addEventListener('resize', handleResize);

    return () => window.removeEventListener('resize', handleResize);
  }, [handleResize]);

  useEffect(() => {
    if (!loading) {
      forcePlay();
    }
  }, [forcePlay, loading]);

  return (
    <Container>
      <Player
        ref={ref}
        url={videoUrl}
        volume={0}
        wrapper={Wrapper}
        width="100%"
        height="100%"
        playIcon={null}
        poster={null}
        playing
        playsinline
        loop
        muted
      />
    </Container>
  );
};

Snap1.defaultProps = { loading: false };
Snap1.propTypes = { loading: PropTypes.bool };

export default Snap1;
