import { pipe, times, map, includes } from 'ramda';

export const checkVisible = (currentIndex, index, length) => {
  const circles = Math.floor(currentIndex / length);
  const restIndex = currentIndex - circles * length;

  if (length < 2) return [true, true];
  if (length === 2) return [restIndex === index, restIndex === index];

  return [
    pipe(
      times(($) => -3 + $),
      map((v) => {
        const diff = restIndex + v;

        if (diff < 0) return length + diff;
        if (diff > length - 1) return diff - length - 1;

        return diff;
      }),
      includes(index)
    )(7),
    restIndex === index,
  ];
};
