import React, { useRef } from 'react';
import { useTranslation } from 'react-i18next';

import Pause from 'assets/svg/PauseIcon';
import Play from 'assets/svg/PlayIcon';

import { useRadioActions } from './hooks';
import { Button, IconWrap, TextWrap, Text, Audio } from './styles';

const Radio = () => {
  const ref = useRef();
  const { t } = useTranslation();
  const { enabled, track, isPlay, url, mimeType, handleClick, handleAnimation, onPlay, onPause } = useRadioActions(ref);

  return (
    <Button type="button" onClick={handleClick} disabled={!enabled}>
      <IconWrap>{isPlay ? <Pause /> : <Play />}</IconWrap>
      <TextWrap $isVisible={isPlay} onAnimationComplete={handleAnimation}>
        <Text $isVisible={isPlay && track}>{track && t('Now playing', { track })}</Text>
      </TextWrap>
      {enabled && (
        <Audio ref={ref} preload="auto" onPlay={onPlay} onPause={onPause}>
          <source src={url} type={mimeType} />
        </Audio>
      )}
    </Button>
  );
};

export default Radio;
