import styled from 'styled-components';
import { motion } from 'framer-motion';

export const Container = styled.div`
  flex: 1 1 auto;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
  width: 100%;
  height: calc(50% + 275px);
  min-height: calc(50% + 275px);
  margin: 0 auto 20px auto;
  position: ${({ $isFixed }) => ($isFixed ? 'fixed' : 'static')};
  z-index: ${({ $isFixed }) => ($isFixed ? -1 : 0)};
  @media (max-width: 699px), (max-height: 699px) {
    height: calc(50% + 185px);
    min-height: calc(50% + 185px);
  }
`;

export const Card = styled(motion.div)`
  width: 100%;
  max-width: 530px;
  aspect-ratio: 1/1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 15px;
  background-color: var(--color-grey-ef);
  position: relative;
  overflow: hidden;
  will-change: scale, filter, opacity;
  ${({ $imageUrl }) =>
    $imageUrl &&
    `
      background-image: url(${$imageUrl});
      background-repeat: no-repeat;
      background-size: cover;
      background-position: initial;
  `}
  @media (max-width: 699px), (max-height: 699px) {
    max-width: 350px;
  }
`;

export const VideoWrap = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 0;
  height: 100%;
  min-height: 100%;
  width: auto;
  min-width: 100%;
  background-color: var(--color-grey-ef);
  pointer-events: none;
  & > div {
    height: 100%;
    aspect-ratio: 16/9;
  }
  & iframe {
    height: 100%;
    aspect-ratio: 16/9;
  }
`;

export const Title = styled.h2`
  font: var(--font-32);
  letter-spacing: -0.5px;
  color: ${({ $color }) => $color};
  text-align: center;
  text-transform: uppercase;
  z-index: 1;
  -webkit-transform: translate3d(0, 0, 0);
  margin: 0 60px;
  @media (orientation: landscape) and (max-width: 699px), (max-height: 699px) {
    font: var(--font-22);
    letter-spacing: -0.4px;
    margin: 0 35px;
  }
  @media (orientation: portrait) and (max-width: 699px) {
    font: var(--font-22);
    letter-spacing: -0.4px;
    margin: 0 35px;
  }
`;
