import styled from 'styled-components';

export const Container = styled.div`
  flex: 1 1 auto;
  display: flex;
  flex-wrap: wrap;
  column-gap: 30px;
  width: 100%;
  max-width: fit-content;
  margin: 63px auto;
  padding: 0 15px;
`;

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 20px;
  margin-bottom: 20px;
  width: max-content;
`;

export const Title = styled.h2`
  font: var(--font-19);
  color: var(--color-black);
  text-transform: uppercase;
`;

export const MarkdownText = styled.div`
  columns: ${({ $isLarge }) => ($isLarge ? 2 : 1)};
  column-width: 100px;
  column-fill: balance;
  & > p {
    font: var(--font-14);
    letter-spacing: 0.25px;
    color: var(--color-black);
  }
  & > p + p {
    margin-top: 1em;
  }
  @media (max-width: 991px) {
    columns: 2;
  }
`;

export const ErrMessage = styled.p`
  font: var(--font-32);
  color: var(--color-red);
  text-align: center;
  margin: 63px 1em 1em;
`;
