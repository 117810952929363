import styled from 'styled-components';

export const Container = styled.header`
  display: grid;
  grid-template-columns: auto auto 1fr auto auto;
  grid-template-rows: 48px;
  grid-auto-columns: auto;
  justify-content: start;
  align-items: center;
  position: fixed;
  top: ${({ $isHidden }) => ($isHidden ? -50 : 0)}px;
  left: 0;
  width: 100%;
  height: 48px;
  max-width: 100%;
  overflow: scroll;
  z-index: 100;
  &::-webkit-scrollbar {
    display: none;
  }
  -ms-overflow-style: none;
  scrollbar-width: none;
  @media (max-width: 660px) {
    ${({ $isWhiteBackground }) => $isWhiteBackground && 'background-color: var(--color-white);'}
  }
`;
