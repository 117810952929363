import styled from 'styled-components';

import checkedIcon from './checkedIcon.svg';

export const Label = styled.label`
  display: flex;
  align-items: center;
  column-gap: 12px;
  color: var(--color-grey-a7);
  cursor: pointer;
`;

export const Input = styled.input`
  width: 23px;
  min-width: 23px;
  height: 23px;
  background-color: var(--color-grey-ef);
  border: 2px solid var(--color-grey-a7);
  transition: border-color 0.3s;
  cursor: pointer;
  &:checked {
    border-color: var(--color-black);
    background-image: url(${checkedIcon});
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
  }
  &:checked + span {
    color: var(--color-black);
  }
`;

export const Span = styled.span`
  font: var(--font-14);
  letter-spacing: 0.25px;
  color: inherit;
  transition: color 0.3s;
`;

export const Button = styled.button`
  width: fit-content;
  height: 42px;
  border-radius: 21px;
  font: var(--font-14);
  letter-spacing: 0.25px;
  text-transform: uppercase;
  color: var(--color-white);
  background-color: var(--color-black);
  padding: 0 17px;
  margin: 20px;
`;
