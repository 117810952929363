import styled from 'styled-components';

import { Section } from '../styles';

export const Container = styled(Section)`
  background-color: var(--color-white);
  padding: 63px 0;
  @media (max-height: 959px), (max-width: 1009px) {
    padding: 15px 15px 0;
  }
`;

export const Wrapper = styled.div`
  width: 100%;
  max-width: 690px;
  height: auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 30px;
  background-color: var(--color-black);
  @media (max-width: 1009px) {
    padding: 15px;
  }
`;

export const Title = styled.h2`
  font: var(--font-19);
  letter-spacing: 0px;
  color: var(--color-white);
  text-transform: uppercase;
  margin-bottom: 0.5em;
  max-width: 630px;
`;

export const Text = styled.div`
  color: var(--color-white);
  & > p {
    font: var(--font-19);
    letter-spacing: 0px;
    color: var(--color-white);
    margin-bottom: 1em;
    max-width: 630px;
  }
`;

export const Box = styled.div`
  background-color: var(--color-black);
  border: 2px solid var(--color-white);
  color: var(--color-white);
  padding: 15px;
  & > p {
    font: var(--font-19);
    letter-spacing: 0px;
    color: var(--color-white);
  }
  & > p > a {
    font: var(--font-19);
    letter-spacing: 0px;
    color: var(--color-white);
  }
  & > p > a:active {
    color: var(--color-grey-ef);
  }
  @media (hover) {
    & > p > a:hover {
      color: var(--color-grey-ef);
    }
  }
  @media (max-width: 1009px) {
    & > p,
    & > p > a {
      font: var(--font-14);
      letter-spacing: 0.25px;
    }
  }
`;
