import styled from 'styled-components';

export const Container = styled.div`
  max-width: 630px;
  width: 100%;
  margin: 48px auto 15px;
`;

export const Title = styled.h2`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 48px;
  min-height: 48px;
  background-color: var(--color-white);
  font: var(--font-19);
  letter-spacing: 0px;
  color: var(--color-black);
  text-transform: uppercase;
  @media (max-width: 660px) {
    position: sticky;
    top: 48px;
    padding: 0 15px;
  }
`;

export const SectionName = styled.h3`
  font: var(--font-14);
  letter-spacing: 0.25px;
  color: var(--color-black);
  text-transform: uppercase;
  padding: 30px 0 15px;
  @media (max-width: 660px) {
    padding: 30px 15px 15px;
  }
`;

export const Section = styled.section`
  display: grid;
  grid-template-columns: 1fr;
  grid-auto-flow: row;
  row-gap: 2px;
  width: 100%;
  margin-bottom: 30px;
`;

export const SectionItem = styled.div`
  display: grid;
  grid-template-columns: 100px 1fr;
  align-items: center;
  column-gap: 15px;
  padding: 0 15px;
  background-color: var(--color-black);
  height: 48px;
`;

export const Text = styled.p`
  font: var(--font-14);
  letter-spacing: 0.25px;
  color: var(--color-white);
  text-transform: uppercase;
`;

export const TextLink = styled.a`
  font: var(--font-14);
  letter-spacing: 0.25px;
  color: var(--color-white);
  text-transform: uppercase;
`;

export const Warn = styled.div`
  flex: 1 1 auto;
  background-color: var(--color-black);
  padding: 63px 15px 15px;
`;

export const WarnText = styled.p`
  font: var(--font-19);
  letter-spacing: 0px;
  color: var(--color-white);
  text-transform: uppercase;
`;

export const LinkWrap = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  gap: 8px;
  margin: 15px 0;
  @media (max-width: 660px) {
    margin: ${({ $isWarn }) => ($isWarn ? '15px 0' : '15px 15px 0')};
  }
`;

export const Button = styled.button`
  display: flex;
  align-items: center;
  width: fit-content;
  height: 42px;
  padding: 1px 17px 0;
  border-radius: 21px;
  background-color: ${({ $isWarn }) => ($isWarn ? 'var(--color-white)' : 'var(--color-grey-ef)')};
  font: var(--font-14);
  letter-spacing: 0.25px;
  color: var(--color-black);
  text-decoration: none;
  text-transform: uppercase;
  @media (hover) {
    &:hover {
      color: var(--color-grey-a7);
    }
  }
`;

export const ExternalLink = styled.a`
  display: flex;
  align-items: center;
  width: fit-content;
  height: 42px;
  padding: 1px 17px 0;
  border: 1px solid;
  border-radius: 21px;
  background: none;
  font: var(--font-14);
  letter-spacing: 0.25px;
  color: ${({ $isWarn }) => ($isWarn ? 'var(--color-white)' : 'var(--color-black)')};
  text-decoration: none;
  text-transform: uppercase;
  @media (hover) {
    &:hover {
      background-color: var(--color-black);
      color: var(--color-white);
    }
  }
`;
