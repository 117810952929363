import styled from 'styled-components';
import { motion } from 'framer-motion';

export const Container = styled.div`
  display: grid;
  grid-template-columns: 1fr auto;
  grid-template-rows: 48px;
  justify-content: space-between;
  align-items: center;
  column-gap: 9px;
  margin-left: 9px;
  z-index: 10;
  @media (max-width: 991px) {
    column-gap: 0;
    margin-left: 2px;
  }
`;

export const Wrapper = styled(motion.div)`
  display: grid;
  grid-template-columns: ${({ $length }) => `repeat(${$length}, auto)`};
  grid-template-rows: 42px;
  justify-content: start;
  width: 100%:
  max-width: 100%;
  overflow-x: scroll;
  &::-webkit-scrollbar {
    display: none;
  }
  -ms-overflow-style: none;
  scrollbar-width: none;
  
`;

export const Items = styled.div`
  display: grid;
  grid-template-columns: ${({ $length }) => `repeat(${$length}, auto)`};
  grid-template-rows: 100%;
  width: auto;
  margin-left: 9px;
`;

export const Button = styled.button`
  height: 100%;
  width: fit-content;
  border-radius: 21px;
  background-color: var(--color-black);
  font: var(--font-14);
  line-height: 1;
  letter-spacing: 0.25px;
  text-transform: uppercase;
  color: var(--color-white);
  padding: 1px 17px 0;
  @media (hover) {
    &:hover {
      color: var(--color-grey-a7);
    }
  }
`;

export const ItemButton = styled(motion.button).attrs(({ $isOpen }) => ({
  animate: { width: $isOpen ? 'max-content' : 0, marginRight: $isOpen ? 9 : 0, opacity: $isOpen ? 1 : 0 },
  transition: { duration: 0.3 },
  initial: false,
}))`
  height: 100%;
  width: 0;
  border-radius: 21px;
  background-color: var(--color-green);
  overflow: hidden;
  margin-right: 0;
  opacity: 0;
`;

export const ContentWrap = styled.div`
  display: flex;
  align-items: center;
  width: max-content;
  padding: 1px 17px 0;
`;

export const Span = styled.span`
  font: var(--font-14);
  line-height: 1;
  letter-spacing: 0.25px;
  text-transform: uppercase;
  color: var(--color-black);
`;

export const IconWrap = styled(motion.div).attrs(({ $isSelected }) => ({
  animate: { width: $isSelected ? 11 : 0, marginLeft: $isSelected ? 9 : 0, opacity: $isSelected ? 1 : 0 },
  transition: { duration: 0.3 },
  initial: false,
}))`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 0;
  height: 11px;
  opacity: 0;
  margin-left: 0;
  padding-bottom: 1px;
`;

export const BlackButton = styled.button`
  height: 100%;
  width: fit-content;
  background-color: var(--color-black);
  font: var(--font-14);
  line-height: 1;
  letter-spacing: 0.25px;
  text-transform: uppercase;
  color: var(--color-white);
  padding: 1px 17px 0;
  @media (hover) {
    &:hover {
      color: var(--color-grey-a7);
    }
  }
`;
