export const ROUTES = [
  { to: '/', name: 'About' },
  { to: '/calendar', name: 'Calendar' },
  { to: '/radio', name: 'Radio' },
  { to: '/space', name: 'Space' },
];

export const PLAYER_CONFIG = {
  file: {
    attributes: {
      preload: 'metadata',
    },
  },
  youtube: {
    playerVars: {
      origin: process.env.REACT_APP_URL,
      cc_load_policy: 0,
      disablekb: 1,
      fs: 0,
      iv_load_policy: 3,
      loop: 1,
      modestbranding: 1,
      playsinline: 1,
      rel: 0,
      showinfo: 0,
    },
    embedOptions: {
      origin: process.env.REACT_APP_URL,
      cc_load_policy: 0,
      disablekb: 1,
      fs: 0,
      iv_load_policy: 3,
      loop: 1,
      modestbranding: 1,
      playsinline: 1,
      rel: 0,
      showinfo: 0,
    },
  },
};
