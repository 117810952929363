import React, { useLayoutEffect } from 'react';
import PropTypes from 'prop-types';

import CloseIcon from 'assets/svg/CloseIcon';

import { Container, Button, Text } from './styles';

const Thanks = ({ onClose, text }) => {
  useLayoutEffect(() => {
    const header = document.querySelector('header');
    header.style.zIndex = 'auto';

    return () => {
      header.style.zIndex = 100;
    };
  });

  return (
    <Container>
      <Button type="button" onClick={onClose}>
        <CloseIcon />
      </Button>
      <Text>{text}</Text>
    </Container>
  );
};

Thanks.defaultProps = { text: '' };
Thanks.propTypes = {
  onClose: PropTypes.func.isRequired,
  text: PropTypes.string,
};

export default Thanks;
