import { createContext } from 'react';

export const appDefaultContext = {
  eventsObject: {},
  pastEvents: [],
  futureEvents: [],
  filteredPastEvents: [],
  filteredFutureEvents: [],
  filters: {},
  filtersMenu: [],
  showList: true,
  radio: {},
  isUpdated: false,
};
export const AppContext = createContext([appDefaultContext, () => null]);
