import React, { useRef, Fragment } from 'react';
import { useTranslation } from 'react-i18next';

import { useSeparatedList } from './hooks';

import Section from './Section';
import { Container, Wrapper, Label, LinkWrap, Link, InternLink } from './styles';

const List = () => {
  const wrapperRef = useRef();
  const { t } = useTranslation();
  const [futureEvents, pastEvents] = useSeparatedList();

  return (
    <Container ref={wrapperRef}>
      <Wrapper>
        {futureEvents.map(({ id, date, events, yearLabel }) => (
          <Fragment key={id}>
            <Section date={date} events={events} isFuture />
            {yearLabel && <Label>{yearLabel}</Label>}
          </Fragment>
        ))}
        {Boolean(futureEvents.length && pastEvents.length) && <Label>{t('Past events')}</Label>}
        {pastEvents.map(({ id, date, events, yearLabel }) => (
          <Fragment key={id}>
            <Section date={date} events={events} />
            {yearLabel && <Label>{yearLabel}</Label>}
          </Fragment>
        ))}
      </Wrapper>
      <LinkWrap>
        <Link href="https://www.instagram.com/summer_camp.space/" target="_blank" rel="noopener noreferrer">
          Instagram
        </Link>
        <Link href="https://soundcloud.com/summer_camp/" target="_blank" rel="noopener noreferrer">
          SoundCloud
        </Link>
        <InternLink to="/imprint">{t('Imprint')}</InternLink>
      </LinkWrap>
    </Container>
  );
};

export default List;
