import styled from 'styled-components';

export const Container = styled.section`
  height: 100%;
  min-height: 100%;
  max-height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  scroll-snap-align: start;
  scroll-snap-stop: always;
  overflow: hidden;
  background-color: var(--color-grey-99);
  position: relative;
`;

export const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  min-width: 100%;
  height: 100%;
  min-height: 100%;
  & video {
    /* height: 100%;
    min-height: 100%; */
    object-fit: cover;
    pointer-events: none;
  }
  /* @media (orientation: portrait) {
    & video {
      width: 100%;
      min-width: 100%;
    }
  } */
`;
