import { useCallback, useEffect } from 'react';
import { useLazyQuery } from '@apollo/client';
import { useMotionValue } from 'framer-motion';

import { appDefaultContext } from 'utils/contexts';
import { GET_DATA } from 'utils/gqls';
import useAsyncState from 'utils/useAsyncState';
import { parseServerData } from 'utils';

export const useServerData = () => {
  const indexValue = useMotionValue(0);
  const context = useAsyncState({ ...appDefaultContext, indexValue });
  const setContext = context[1];

  const onCompleted = useCallback(
    ({ page, categories }) => {
      const { eventsObject, pastEvents, futureEvents, filteredPastEvents, filteredFutureEvents, filtersMenu, filters, radio } =
        parseServerData(page, categories);

      if (pastEvents.length || futureEvents.length) {
        setContext(($) => ({
          ...$,
          eventsObject,
          pastEvents,
          futureEvents,
          filteredPastEvents,
          filteredFutureEvents,
          filtersMenu,
          filters,
          radio,
          isUpdated: true,
        }));
      }
    },
    [setContext]
  );

  const [loadData, { called, loading, error }] = useLazyQuery(GET_DATA, { onCompleted });

  useEffect(() => {
    if (!(called || loading)) loadData();
  }, [called, loadData, loading]);

  return [context, error && (error.message || 'Oops, error occurred...')];
};
