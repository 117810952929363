import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import detector from 'i18next-browser-languagedetector';
import { cond, anyPass, T, pipe, take, equals } from 'ramda';

import { EN, DE, DEFAULT_LANGUAGE, LOCALES } from './consts';

const checkLanguage = () => {
  cond([
    [anyPass([equals(EN), equals(DE)]), T],
    [pipe(take(2), equals(EN)), () => i18n.changeLanguage(EN)],
    [pipe(take(2), equals(DE)), () => i18n.changeLanguage(DE)],
    [T, () => i18n.changeLanguage(DEFAULT_LANGUAGE)],
  ])(i18n.language);
};

export const requireFn = (lang) => (acc, name) => {
  acc[name] = require(`./${lang}/${name}.json`); // eslint-disable-line import/no-dynamic-require

  return acc;
};

const resources = {
  [DE]: LOCALES.reduce(requireFn(DE), {}),
  [EN]: LOCALES.reduce(requireFn(EN), {}),
};

i18n
  .use(detector)
  .use(initReactI18next)
  .init(
    {
      resources,
      fallbackLng: DEFAULT_LANGUAGE,
      supportedLngs: [EN, DE],
      defaultNS: 'app',
      keySeparator: false,
      nsSeparator: ':',
      ns: LOCALES,
      debug: process.env.NODE_ENV === 'development',
      interpolation: {
        escapeValue: false,
      },
    },
    checkLanguage
  );

export { EN, DE, DEFAULT_LANGUAGE };
export default i18n;
